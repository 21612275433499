import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
    verifyPermission, 
    permissionsStatus 
} from '../../helpers/permissions'

const getUsersPolicies = async ({ limit = 500, offset = 0 }) => {
    if(!verifyPermission('users_policies', permissionsStatus.list)) return []

    const { data } = await API.get(URLS.users_policies.list(limit, offset))

    return data
}

const createUserPolicy = async ({ policy }) => {
    if(!verifyPermission('users_policies', permissionsStatus.create)) return {}

    const { data: response, isError } = await API.post(URLS.users_policies.create, policy)

    return response?.data || isError
}

const updateUserPolicy = async ({ policy, id }) => {
    if(!verifyPermission('users_policies', permissionsStatus.update)) return {}

    const { data: response, isError } = await API.put(URLS.users_policies.update(id), policy)

    return response?.data || isError
}

const deleteUserPolicy = async ({ id }) => {
    if(!verifyPermission('users_policies', permissionsStatus.delete)) return {}

    const { data: response, isError } = await API.delete(URLS.users_policies.delete(id))

    return response?.data || isError
}

const UserPolicies = {
    getUsersPolicies,
    createUserPolicy,
    updateUserPolicy,
    deleteUserPolicy
}

export default UserPolicies