import React, { useState, useEffect } from "react"
import { 
    Tag,
    Button,
    Divider,
    Switch,
    Tooltip,
    Select,
    Input,
    Alert,
    Space,
    Form,
    Tabs,
    Collapse,
    Typography
} from 'antd'
import {
    CheckOutlined,
    CloseOutlined,
    InfoCircleOutlined,
    ThunderboltOutlined,
    CoffeeOutlined
} from '@ant-design/icons'
import Editor from '@monaco-editor/react'

const { Item } = Form

export default function TabGet ({ handleValue = () => {}, values = {}, isEdit = false, appId = null, endpointName = null, endpointName2 = null }) {
    const [requestParams, setRequestParams] = useState(values?.request),
        [responseParams, setResponseParams] = useState(values?.response?.schema),
        [endpointDisabled, setEndpointDisabled] = useState(values?.request?.status || false),
        [rawQuery, setRawQuery] = useState(false),
        [responsePreview, setResponsePreview] = useState(false),
        [responseParamInputValue, setResponseParamInputValue] = useState(''),
        [saveButtonDisabled, setSaveButtonDisabled] = useState(true),
        [editorCodePrd, setEditorCodePrd] = useState(''),
        [editorCodeHml, setEditorCodeHml] = useState(''),
        [editorCodeSdb, setEditorCodeSdb] = useState(''),
        [editorCodeDev, setEditorCodeDev] = useState(''),
        [mergeValues, setMergeValues] = useState([])

    const handleShowResponsePreview = () => {
        const newResponsePreview = !responsePreview ? true : false
        setResponsePreview(newResponsePreview)
    }

    const handleMergeValues = (index, indexMerge, value) => {
        mergeValues[index][indexMerge] = value

        setMergeValues([ ...mergeValues ])
    }

    const handleSaveQuerySchema = () => {
        const mergeValue1 = mergeValues?.[0]?.join('|') || [],
            mergeValue2 = mergeValues?.[1]?.join('|') || []

        if(mergeValue1?.length > 0) {
            if(!mergeValues?.[0]?.[1]?.match('as ')?.length) {
                return Notification({
                    title: 'Salvar Queries',
                    description: "A chave da query 'data.1' necessita ser igual a 'exemplo as chave' ou 'as chave'",
                    type: 'warning'
                })
            }

            if(!mergeValues?.[0]?.[2]?.match('=')?.length || (!mergeValues?.[0]?.[2]?.split('=')?.[0]?.length || !mergeValues?.[0]?.[2]?.split('=')?.[1]?.length)) {
                return Notification({
                    title: 'Salvar Queries',
                    description: "O compare da query 'data.1' necessita ser igual a 'exemplo_id=id'",
                    type: 'warning'
                })
            }

            handleValue({ target: { value: mergeValue1  } }, 'request.show.merge_values', 'array.string.0')
        }

        if(mergeValue2?.length > 0) {
            if(!mergeValues?.[1]?.[1]?.match(' as ')?.length) {
                return Notification({
                    title: 'Salvar Queries',
                    description: "A chave da query 'data.2' necessita ser igual a 'exemplo as chave'",
                    type: 'warning'
                })
            }

            if(!mergeValues?.[1]?.[2]?.match('=')?.length || (!mergeValues?.[1]?.[2]?.split('=')?.[0]?.length || !mergeValues?.[1]?.[2]?.split('=')?.[1]?.length)) {
                return Notification({
                    title: 'Salvar Queries',
                    description: "O compare da query 'data.2' necessita ser igual a 'exemplo_id=id'",
                    type: 'warning'
                })
            }

            handleValue({ target: { value: mergeValue2  } }, 'request.show.merge_values', 'array.string.1')
        }
    }

    const showResponsePreview = () => {
        const obj = {}

        responseParams.forEach((param, i) => {
            Object.assign(obj, { [param]: `preview_${param}` })
        })

        return JSON.stringify({ response_data: obj }, null, 2)
    }

    const handleEnableEndpoint = () => {
        const newValue = !endpointDisabled ? true : false
        setEndpointDisabled(newValue)
        handleValue({ target: { value: Number(newValue) } }, 'request.show.status')
    }

    const handleEnableRawQuery = () => {
        const newValue = !rawQuery ? true : false
        setRawQuery(newValue)

        // if(!newValue) {
        //     handleValue({ target: { value: null } }, 'request.show.raw_query')
        // } else if (isEdit) {
        //     handleValue({ target: { value: null } }, 'request.show.raw_query')
        // }
    }

    const handleRemoveParam = (value, type) => {
        switch (type) {
            case 'response':
                handleValue({ target: { value } }, 'response.show.schema', 'array', true)
                break
        }
    }

    useEffect(() => {
        setEditorCodePrd('')
        setEditorCodeHml('')
        setEditorCodeSdb('')
        setEditorCodeDev('')
    }, [appId])

    useEffect(() => {
        setResponseParams(values?.response?.schema)
        setRequestParams(values?.request)
        setEndpointDisabled(Boolean(values?.request?.status))

        if(values?.request?.merge_values?.length > 0) {
            const mergeValue0Split = values?.request?.merge_values?.[0].split('|') || [],
                mergeValues1Split = values?.request?.merge_values?.[1].split('|') || []

            let mergeValues = []

            if(mergeValue0Split?.length) {
                mergeValues.push([mergeValue0Split[0], mergeValue0Split[1], mergeValue0Split[2], mergeValue0Split[3]])
            }

            if(mergeValues1Split?.length) {
                mergeValues.push([mergeValues1Split[0], mergeValues1Split[1], mergeValues1Split[2], mergeValues1Split[3]])
            }
            
            setMergeValues([ ...mergeValues ])
        } else {
            setMergeValues([
                ['data'],
                []
            ])
        }

        if(values?.request?.raw_query?.length) {
            setRawQuery(true)
        } else {
            setRawQuery(false)
        }

        setSaveButtonDisabled(true)
    }, [values, appId])

    useEffect(() => {
        if((values?.request?.dev_mode?.prd.code[0])) {
            const codePrd = values?.request?.dev_mode?.prd.code
            if((editorCodePrd.length != codePrd.length)) setEditorCodePrd(codePrd)
        }

        if((values?.request?.dev_mode?.hml.code[0])) {
            const codeHml = values?.request?.dev_mode?.hml.code
            if((editorCodeHml.length != codeHml.length)) setEditorCodeHml(codeHml)
        }

        if((values?.request?.dev_mode?.sdb.code[0])) {
            const codeSdb = values?.request?.dev_mode?.sdb.code
            if((editorCodeSdb.length != codeSdb.length)) setEditorCodeSdb(codeSdb)
        }

        if(values?.request?.dev_mode?.dev.code[0]) {
            const codeDev = values?.request?.dev_mode?.dev.code
            if((editorCodeDev.length != codeDev.length)) setEditorCodeDev(codeDev)
        } 
    }, [requestParams])

    const handleSaveCode = () => {
        const codePrd = values?.request?.dev_mode?.prd.code
        if((editorCodePrd.length != codePrd.length)) handleValue(editorCodePrd, 'request.show.dev_mode.prd.code')

        const codeHml = values?.request?.dev_mode?.hml.code
        if((editorCodeHml.length != codeHml.length)) handleValue(editorCodeHml, 'request.show.dev_mode.hml.code')

        const codeSdb = values?.request?.dev_mode?.sdb.code
        if((editorCodeSdb.length != codeSdb.length)) handleValue(editorCodeSdb, 'request.show.dev_mode.sdb.code')

        const codeDev = values?.request?.dev_mode?.dev.code
        if((editorCodeDev.length != codeDev.length)) handleValue(editorCodeDev, 'request.show.dev_mode.dev.code')

        setSaveButtonDisabled(true)
    }

    const tabsEnv = [
        <>
            <h3 style={{ textAlign: 'left' }}>
                Produção
            </h3>
            <Editor
                language='javascript'
                value={editorCodePrd || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodePrd(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Homologação
            </h3>
            <Editor
                language='javascript'
                value={editorCodeHml || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeHml(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Sandbox
            </h3>
            <Editor
                language='javascript'
                value={editorCodeSdb || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeSdb(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Desenvolvimento
            </h3>
            <Editor
                language='javascript'
                value={editorCodeDev || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeDev(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>
    ]

    const tabs = [
        <>
            <Collapse
                accordion={true}
                bordered={false}
            >
                <Collapse.Panel header={
                    <>
                        <Typography.Title level={5} style={{ textAlign: 'left', marginTop: 3 }}>
                            {'Requisição'}
                            <Tooltip placement="topLeft" title="Configurações da requisição">
                                <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                            </Tooltip>    
                        </Typography.Title>
                    </>
                }>
                    <Item label={(<>
                        {'RAW Query'}
                        <Tooltip placement="topLeft" title={`
                            Você pode criar uma RAW Query e passar valores com a expressão {nome_variavel}... caso tenha autenticação, o sistema irá tentar preencher a variável
                            buscando O MESMO NOME dentro da sessão criada através do REVERSE AUTH, caso não encontre, buscará na QUERY STRING da URL.
                            É permitido sempre utilizar QUERY STRING independente de uma RAW Query
                            PS: Será retornado apenas o primeiro registro, por isso é aconselhável utilizar o ID do endpoint na query
                            `}>
                            <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                        </Tooltip>
                        <Tooltip placement="topLeft" title="Raw query ativo/desativado">
                            <Switch 
                                onChange={handleEnableRawQuery}
                                style={{ float: 'right', marginLeft: 10 }}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={rawQuery}
                            />
                        </Tooltip>
                        </>)}
                    >
                        {
                            rawQuery && (
                                <>
                                    <Input.TextArea 
                                        placeholder={'SELECT * FROM {table} WHERE id = {id} AND user_id = {user_id}'}
                                        value={requestParams?.raw_query?.[0]}
                                        onChange={(e) => handleValue(e, 'request.show.raw_query', 'array.string.0')}
                                    />
                                    {/* <Button style={{ float: 'right', marginTop: 10 }} type="default" shape="round" onClick={() => handleSaveQuerySchema()}>Salvar Query</Button> */}
                                    <Typography.Paragraph style={{ marginTop: 10, cursor: 'pointer' }}>Default vars: {'{table}, {id}'}</Typography.Paragraph>
                                </>
                            )
                        }
                    </Item>
                </Collapse.Panel>
                <Collapse.Panel header={
                    <>
                        <Typography.Title level={5} style={{ textAlign: 'left', marginTop: 3 }}>
                            {'Resposta'}
                            <Tooltip placement="topLeft" title="Configurações da resposta da requisição">
                                <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                            </Tooltip>    
                        </Typography.Title>
                    </>
                }>
                    <Item>
                        <Input 
                            style={{ 
                                marginBottom: 20
                            }}
                            
                            placeholder={'Digite o parâmetro exatamente como irá retornar do banco de dados e dê enter'}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleValue(e, 'response.show.schema', 'array')
                                    // handleNewParam(e.target.value, 'response')
                                    setResponseParamInputValue('')
                                }
                            }}
                            value={responseParamInputValue}
                            onChange={(e) => setResponseParamInputValue(e.target.value)}
                        />
                        <Select
                            mode="tags"
                            style={{
                                width: '83%',
                            }}
                            allowClear
                            bordered={false}
                            showArrow={false}
                            options={responseParams?.map(param => {
                                return {
                                    label: param,
                                    value: param
                                }
                            })} 
                            onClear={(e) => handleRemoveParam(e, 'response')}
                            onChange={(e) => handleRemoveParam(e, 'response')}
                            dropdownStyle={{ display: 'none' }} 
                            onInputKeyDown={(e) => e.preventDefault()}
                            value={responseParams}
                        />
                        <Button style={{ float: 'right' }} type="default" shape="round" onClick={() => handleShowResponsePreview()}>Ver resposta</Button>
                    </Item>
                    {responsePreview && (
                        <Space
                            direction="vertical"
                            style={{
                                width: '100%',
                                marginTop: 10
                            }}
                        >
                            <Alert 
                                closable={true}
                                onClose={() => handleShowResponsePreview()}
                                message="Preview de resposta"
                                description={<pre>{showResponsePreview()}</pre>}
                                type="success"
                            />
                        </Space>
                    )}
                </Collapse.Panel>
            </Collapse>
            {/* <Divider orientation="left">
                Requisição
                <Tooltip placement="topLeft" title="Configurações da requisição">
                    <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                </Tooltip>    
            </Divider> */}
                
            {/* <div id={'request-params'}>
                <Item label={(<>
                    {'RAW Query'}
                    <Tooltip placement="topLeft" title={`
                        Você pode criar uma RAW Query e passar valores com a expressão {nome_variavel}... caso tenha autenticação, o sistema irá tentar preencher a variável
                        buscando O MESMO NOME dentro da sessão criada através do REVERSE AUTH, caso não encontre, buscará na QUERY STRING da URL.
                        É permitido sempre utilizar QUERY STRING independente de uma RAW Query
                        PS: Será retornado apenas o primeiro registro, por isso é aconselhável utilizar o ID do endpoint na query
                        `}>
                        <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                    </Tooltip>
                    <Tooltip placement="topLeft" title="Raw query ativo/desativado">
                        <Switch 
                            onChange={handleEnableRawQuery}
                            style={{ float: 'right', marginLeft: 10 }}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={rawQuery}
                        />
                    </Tooltip>
                    </>)}
                >
                    {
                        rawQuery && (
                            <>
                                <Input
                                    addonBefore={'data'}
                                    type={'text'}
                                    placeholder={'SELECT * FROM {table} WHERE id = {id} AND user_id = {user_id} LIMIT {limit} OFFSET {offset}'}
                                    value={requestParams?.raw_query?.[0]}
                                    onChange={(e) => handleValue(e, 'request.show.raw_query', 'array.string.0')}
                                /> */}
                                {/* <Divider />
                                <Input
                                    style={{ marginTop: 10 }}
                                    addonBefore={'data.1'}
                                    type={'text'}
                                    placeholder={'SELECT * FROM {table} WHERE id = {id} AND user_id = {user_id} LIMIT {limit} OFFSET {offset}'}
                                    value={requestParams?.raw_query?.[1]}
                                    onChange={(e) => handleValue(e, 'request.show.raw_query', 'array.string.1') 
                                    }
                                /> */}

                                {/* <Row style={{ marginTop: 10 }}>
                                    <Col span={7}>
                                        <Tooltip placement="topLeft" title="Chave que irá dentro do objeto de 'data', exemplo: data as order_products">
                                            <Input 
                                                addonBefore={'Chave'}
                                                type={'text'}
                                                placeholder={'chave do objeto'}
                                                value={mergeValues?.[0]?.[1] || ''}
                                                onChange={(e) => {
                                                    const value = e.target.value

                                                    handleMergeValues(0, 1, value)
                                                }}
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col span={3}>
                                        <Tooltip placement="topLeft" title="Dados que iremos usar, selecionado pela query">
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                options={[0]?.map(val => {
                                                    const v = 'data'
                                                    return {
                                                        label: v,
                                                        value: val
                                                    } 
                                                })} 
                                                onChange={(e) => {
                                                    const value = e.target.value

                                                    handleMergeValues(0, 0, value)
                                                }}
                                                onInputKeyDown={(e) => e.preventDefault()}
                                                value={'data'}
                                                defaultValue={'data'}
                                                disabled
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col span={8}>
                                        <Tooltip placement="topLeft" title="Valor de comparação entre os dados de 'data.1' e 'data', exemplo: id=order_id">
                                            <Input 
                                                addonBefore={'Compare'}
                                                type={'text'}
                                                placeholder={'exemplo: product_id=id'}
                                                value={mergeValues?.[0]?.[2] || ''}
                                                onChange={(e) => {
                                                    const value = e.target.value.trim()

                                                    handleMergeValues(0, 2, value)
                                                }}
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col span={6}>
                                        <Tooltip placement="topLeft" title="tipo da chave: nenhum = não retorna a chave indicada, array = chave retorna em forma de lista">
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                options={['false', 'array']?.map(val => {
                                                    const v = val == 'false' ? 'nenhum' : val
                                                    return {
                                                        label: v,
                                                        value: val
                                                    } 
                                                })} 
                                                onChange={(e) => {
                                                    const value = e

                                                    handleMergeValues(0, 3, value)
                                                }}
                                                onInputKeyDown={(e) => e.preventDefault()}
                                                value={mergeValues?.[0]?.[3]}
                                                defaultValue={'false'}
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row> */}

                                {/* <Divider /> */}
                                
                                {/* <Input
                                    style={{ marginTop: 10 }}
                                    addonBefore={'data.2'}
                                    type={'text'}
                                    placeholder={'SELECT * FROM {table} WHERE id = {id} AND user_id = {user_id} LIMIT {limit} OFFSET {offset}'}
                                    value={requestParams?.raw_query?.[2]}
                                    onChange={(e) => handleValue(e, 'request.show.raw_query', 'array.string.2')}
                                />

                                <Row style={{ marginTop: 10, marginBottom: 10 }}>
                                    <Col span={7}>
                                        <Tooltip placement="topLeft" title="Chave que irá dentro do objeto de 'data', exemplo: data as order_products">
                                            <Input 
                                                addonBefore={'Chave'}
                                                type={'text'}
                                                placeholder={'chave do objeto'}
                                                value={mergeValues?.[1]?.[1] || ''}
                                                onChange={(e) => {
                                                    const value = e.target.value

                                                    handleMergeValues(1, 1, value)
                                                }}
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col span={3}>
                                        <Tooltip placement="topLeft" title="Dados que iremos usar, selecionado pela query">
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                options={[0, 1]?.map(val => {
                                                    const v = !val ? 'data' : 'data.1'
                                                    return {
                                                        label: v,
                                                        value: val
                                                    } 
                                                })} 
                                                onChange={(e) => {
                                                    const value = e.target.value

                                                    handleMergeValues(1, 0, value)
                                                }}
                                                onInputKeyDown={(e) => e.preventDefault()}
                                                value={mergeValues?.[1]?.[0] || ''}
                                                defaultValue={mergeValues?.[1]?.[0] || ''}
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col span={8}>
                                        <Tooltip placement="topLeft" title="Valor de comparação entre os dados de 'data.1' e 'data', exemplo: id=order_id">
                                            <Input 
                                                addonBefore={'Compare'}
                                                type={'text'}
                                                placeholder={'exemplo: product_id=id'}
                                                value={mergeValues?.[1]?.[2] || ''}
                                                onChange={(e) => {
                                                    const value = e.target.value.trim()

                                                    handleMergeValues(1, 2, value)
                                                }}
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col span={6}>
                                        <Tooltip placement="topLeft" title="tipo da chave: nenhum = não retorna a chave indicada, array = chave retorna em forma de lista">
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                options={['false', 'array']?.map(val => {
                                                    const v = val == 'false' ? 'nenhum' : val
                                                    return {
                                                        label: v,
                                                        value: val
                                                    } 
                                                })} 
                                                onChange={(e) => {
                                                    const value = e

                                                    handleMergeValues(1, 3, value)
                                                }}
                                                onInputKeyDown={(e) => e.preventDefault()}
                                                value={mergeValues?.[1]?.[3]}
                                                defaultValue={'false'}
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row> */}
                                {/* <Button style={{ float: 'right', marginTop: 10 }} type="primary" shape="round" onClick={() => handleSaveQuerySchema()}>Salvar Query</Button>
                            </>
                        )
                    }
                </Item>
            </div> */}

            {/* <Divider orientation="left">
                Resposta 
                <Tooltip placement="topLeft" title="Configurações da resposta da requisição">
                    <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                </Tooltip>
            </Divider>
            <div id={'response-params'}>
                <Input 
                    style={{ 
                        marginBottom: 20
                    }}
                    
                    placeholder={'Digite o parâmetro exatamente como irá retornar do banco de dados e dê enter'}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleValue(e, 'response.show.schema', 'array')
                            // handleNewParam(e.target.value, 'response')
                            setResponseParamInputValue('')
                        }
                    }}
                    value={responseParamInputValue}
                    onChange={(e) => setResponseParamInputValue(e.target.value)}
                />
                <Select
                    mode="tags"
                    style={{
                        width: '83%',
                    }}
                    allowClear
                    bordered={false}
                    showArrow={false}
                    options={responseParams?.map(param => {
                        return {
                            label: param,
                            value: param
                        }
                    })} 
                    onClear={(e) => handleRemoveParam(e, 'response')}
                    onChange={(e) => handleRemoveParam(e, 'response')}
                    dropdownStyle={{ display: 'none' }} 
                    onInputKeyDown={(e) => e.preventDefault()}
                    value={responseParams}
                />
                    <Button style={{ float: 'right' }} type="primary" shape="round" onClick={() => handleShowResponsePreview()}>Ver resposta</Button>
                {responsePreview && (
                    <Space
                        direction="vertical"
                        style={{
                            width: '100%',
                            marginTop: 10
                        }}
                    >
                        <Alert 
                            closable={true}
                            onClose={() => handleShowResponsePreview()}
                            message="Preview de resposta"
                            description={<pre>{showResponsePreview()}</pre>}
                            type="success"
                        />
                    </Space>
                )}
            </div> */}
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Dev Mode
            </h3>
            <h5 style={{ textAlign: 'left' }}>
                Faça o clone do nosso <a href="https://github.com/codetec-repos/simplerest-dev-boilerplate">boilerplate</a> para auxiliar no desenvolvimento <br></br>
                Quando houver código no "Dev Mode" o "Simple Mode" é ignorado.
                O código deverá sempre terminar com um "return Object" ou "return Array", para conter um valor na resposta<br></br>
                Após feita a modificação, clique em "salvar código"  e após salvar, será aberto um PR para aprovação ou não do código<br></br>
                * no Sandbox use apenas o ambiente de "desenvolvimento" para teste
            </h5>
            <Input placeholder="Digite a descrição da Pull Request" value={values?.description} onChange={(e) => handleValue(e, 'request.show.description')} />
            <Tabs
                defaultActiveKey="2"
                items={['Produção', 'Homologação', 'Sandbox', 'Desenvolvimento'].map((tabName, i) => {
                    return {
                        label: (
                        <span>
                            {tabName}
                        </span>
                        ),
                        key: i + 1,
                        children: tabsEnv[i],
                    }
                })}
            />
            <Typography.Paragraph style={{ marginTop: 10, cursor: 'pointer' }}>Default vars: 
                <Tooltip placement="topLeft" title={`
                    Variável process do NodeJS
                `}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>process</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={`
                    Variável require do NodeJS
                `}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>require</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={`
                    Variável com instancia do Knex.js
                `}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>db</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={`
                    const {
                        queryString,
                        session,
                        simpleApiCallService: {
                            call: async (apiId, method : 'list' | 'get' | 'post' | 'put' | 'delete', data = {}, params = {}) => {}
                        }
                    } = simplerest
                `}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>simplerest</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={`
                    const {
                        S3Service,
                        SimplePayments,
                        PDFGenerator,
                        CSVGenerator,
                        DateFormat,
                        SimpleNotifications
                    } = simplelib
                `}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>simplelib</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={`
                    const {
                        email: {
                            config: {
                                host: "",
                                port: "",
                                secure: false
                            },
                            auth: { 
                                user: "",
                                pass: 
                            },
                            emailParams: {
                                from: 
                            }
                        },
                        slack: {
                            auth: {
                                oauthToken: 
                            }
                        },
                        zenvia: {
                            auth: {
                                token: 
                            }
                        },
                        stripe: {
                            auth: {
                                secretKey: ""
                            }
                        },
                        mercadoPago: {
                            auth: {
                                accessToken: "",
                                webhook: 
                            }
                        },
                        aws: {
                            auth: {
                                region: "",
                                accessKey: "",
                                secretKey: ""
                            }
                        }
                    } = simplekeys
                `}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>simplekeys</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={`
                    const {
                        list: async (pattern = '*') => {},
                        get: async (key) => {},
                        set: async (key, value, expires) => {},
                        del: async (key) => {}
                    } = cache
                `}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>cache</Typography.Text>
                </Tooltip>
            </Typography.Paragraph>
            <Button style={{ marginTop: 10, float: 'right' }} disabled={saveButtonDisabled} type="primary" shape="round" onClick={handleSaveCode}>Salvar código</Button>
        </>
    ]

    return (
        <div>
            <h3 style={{ textAlign: 'left' }}>
                <Tag color="success">GET</Tag> {isEdit ? (endpointName2?.length > 0 ? `/v1/${appId}${endpointName?.length > 0 ? `|${endpointName}` : ''}/{id}/${endpointName2}` : `/v1/${appId}${endpointName?.length > 0 ? `|${endpointName}` : ''}/{id}`) : `/v1/{app_id}|{endpoint_name}/{id}/?{endpoint_name2}`}
                <Tooltip placement="topLeft" title="Endpoint ativo/desativado">
                    <Switch 
                        onChange={handleEnableEndpoint}
                        style={{ float: 'right' }}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={endpointDisabled}
                    />
                </Tooltip>
            </h3>

            <Tabs
                defaultActiveKey="2"
                items={[ThunderboltOutlined, CoffeeOutlined].map((Icon, i) => {
                    const tabName = i == 0 ? 'Simple Mode' : 'Dev Mode' 
                    return {
                        label: (
                        <span>
                            <Icon />
                            {tabName}
                        </span>
                        ),
                        key: i + 1,
                        children: tabs[i],
                    }
                })}
            />
        </div>
    )
} 