import React, { useState, useEffect } from "react"
import { 
    Button,
    Tabs,
    Input
} from 'antd'
import Editor from '@monaco-editor/react'

export default function DevMode ({ handleValue = () => {}, values = {}, workerId = null }) {
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true),
    [editorCodePrd, setEditorCodePrd] = useState(''),
    [editorCodeHml, setEditorCodeHml] = useState(''),
    [editorCodeSdb, setEditorCodeSdb] = useState(''),
    [editorCodeDev, setEditorCodeDev] = useState('')

    const handleSaveCode = () => {
        const codePrd = values?.dev_mode?.prd.code
        if(editorCodePrd != codePrd) handleValue(editorCodePrd, 'dev_mode.prd.code')

        const codeHml = values?.dev_mode?.hml.code
        if(editorCodeHml != codeHml) handleValue(editorCodeHml, 'dev_mode.hml.code')

        const codeSdb = values?.dev_mode?.sdb.code
        if(editorCodeSdb != codeSdb) handleValue(editorCodeSdb, 'dev_mode.sdb.code')

        const codeDev = values?.dev_mode?.dev.code
        if(editorCodeDev != codeDev) handleValue(editorCodeDev, 'dev_mode.dev.code')

        setSaveButtonDisabled(true)
    }

    useEffect(() => {
        setEditorCodePrd('')
        setEditorCodeHml('')
        setEditorCodeSdb('')
        setEditorCodeDev('')
    }, [workerId])

    useEffect(() => {
        setSaveButtonDisabled(true)

        if((values?.dev_mode?.prd.code[0])) {
            const codePrd = values?.dev_mode?.prd.code
            if((editorCodePrd.length != codePrd.length)) setEditorCodePrd(codePrd)
        }

        if((values?.dev_mode?.hml.code[0])) {
            const codeHml = values?.dev_mode?.hml.code
            if((editorCodeHml.length != codeHml.length)) setEditorCodeHml(codeHml)
        }

        if((values?.dev_mode?.sdb.code[0])) {
            const codeSdb = values?.dev_mode?.sdb.code
            if((editorCodeSdb.length != codeSdb.length)) setEditorCodeSdb(codeSdb)
        }

        if(values?.dev_mode?.dev.code[0]) {
            const codeDev = values?.dev_mode?.dev.code
            if((editorCodeDev.length != codeDev.length)) setEditorCodeDev(codeDev)
        } 
    }, [values, workerId])

    const tabsEnv = [
        <>
            <h3 style={{ textAlign: 'left' }}>
                Produção
            </h3>
            <Editor
                language='javascript'
                value={editorCodePrd || ''}
                theme='vs-dark'
                height="65vh" 
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodePrd(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Homologação
            </h3>
            <Editor
                language='javascript'
                value={editorCodeHml || ''}
                theme='vs-dark'
                height="65vh" 
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeHml(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Sandbox
            </h3>
            <Editor
                language='javascript'
                value={editorCodeSdb || ''}
                theme='vs-dark'
                height="65vh" 
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeSdb(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Desenvolvimento
            </h3>
            <Editor
                language='javascript'
                value={editorCodeDev || ''}
                theme='vs-dark'
                height="65vh" 
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeDev(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>
    ]

    return (
        <div>
            <h3 style={{ textAlign: 'left' }}>
                Dev Mode
            </h3>
            <h5 style={{ textAlign: 'left' }}>
                O código deverá sempre terminar com um "return Object" ou "return Array", para conter um valor na resposta<br></br>
                Após feita a modificação, clique em "salvar código"  e após salvar, será aberto um PR para aprovação ou não do código<br></br>
            </h5>
            { workerId !== null && <Input placeholder="Digite a descrição da Pull Request" value={values?.description} onChange={(e) => handleValue(e, 'pr_description')} /> }
            <Tabs
                defaultActiveKey="2"
                items={['Produção', 'Homologação', 'Sandbox', 'Desenvolvimento'].map((tabName, i) => {
                    return {
                        label: (
                        <span>
                            {tabName}
                        </span>
                        ),
                        key: i + 1,
                        children: tabsEnv[i],
                    }
                })}
            />
            <Button style={{ marginTop: 10, float: 'right' }} disabled={saveButtonDisabled} type="default" shape="round" onClick={handleSaveCode}>Salvar código</Button>
        </div>
    )
} 