import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
    verifyPermission, 
    permissionsStatus 
} from '../../helpers/permissions'

const getClientConnections = async ({ limit = 500, offset = 0 }) => {
    if(!verifyPermission('connections', permissionsStatus.list)) return []

    const { data } = await API.get(URLS.connections.list(limit, offset))

    return data
}

const testClientConnection = async ({ id }) => {
    const { data } = await API.get(URLS.connections.test(id))

    return data?.data
}

const createClientConnection = async ({ connection }) => {
    if(!verifyPermission('connections', permissionsStatus.create)) return {}

    const { data: response, isError } = await API.post(URLS.connections.create, connection)

    return response?.data || isError
}

const updateClientConnection = async ({ connection, id }) => {
    if(!verifyPermission('connections', permissionsStatus.update)) return {}

    const { data: response, isError } = await API.put(URLS.connections.update(id), connection)

    return response?.data || isError
}

const deleteClientConnection = async ({ id }) => {
    if(!verifyPermission('connections', permissionsStatus.delete)) return {}

    const { data: response, isError } = await API.delete(URLS.connections.delete(id))

    return response?.data || isError
}

const getConnectionLogs = async ({ connectionId, limit = 500, offset = 0 }) => {
    if(!verifyPermission('connections', permissionsStatus.list)) return []

    if(connectionId == null) return []

    const { data: response } = await API.get(URLS.connections.logs(connectionId, limit, offset))

    return response?.data
}

const Connection = {
    getClientConnections,
    createClientConnection,
    updateClientConnection,
    deleteClientConnection,
    getConnectionLogs,
    testClientConnection
}

export default Connection