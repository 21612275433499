import React, { useEffect, useState } from "react"
import { 
    Modal,
    Input,
    Form,
    Select,
    Tooltip,
    Tag,
    Tabs,
    Row,
    Col,
    Divider
} from 'antd'
import {
    InfoCircleOutlined
} from '@ant-design/icons'
import { Notification } from "../.."
import { ACL } from "../../../modules"

const { Item } = Form

export default function ACLModal ({ isMobile = false, title = '', open = false, handleOpen = () => {}, editACL = {}, mutating = () => {} }) {
    const [acl, setACL] = useState({
        'name': null,
        'endpoints': {
            'list': {
                'roles': []
            },
            'show': {
                'roles': []
            },
            'create': {
                'roles': []
            },
            'update': {
                'roles': []
            },
            'delete': {
                'roles': []
            }
        },
        'special_roles': [],
    }),
    [roleInputValue, setRoleInputValue] = useState(''),
    [specialRolesInputValue, setSpecialRolesInputValue] = useState(''),
    [loading, setLoading] = useState(false),
    [tabs, setTabs] = useState([]),
    isEditModal = editACL?._id

    const handleSave = async () => {
        setLoading(true)

        if(!acl?.name?.length) {
            setLoading(false)

            return Notification({ 
                description: `O campo Nome é obrigatório`,
                title: 'Salvar',
                type: 'warning'
            })
        }

        if(isEditModal) {
            await ACL.updateClientACL({ acl, id: acl?._id })
        } else {
            await ACL.createClientACL({ acl })
        }
        
        setLoading(false)
        handleOpen(false)
        handleNotificationSuccess()
        await mutating()
    }

    const handleNotificationSuccess = () => {
        const modalType = isEditModal ? 'editado' : 'criado'
        
        Notification({ 
            description: `ACL ${modalType} com sucesso`,
            title: 'ACLs'
        })
    }

    const handleValue = (e, key, type = null, isDelete = false) => {
        const splitedKeys = key.split('.'),
            hasMoreThanOneKey = splitedKeys.length > 1

        const value = e?.target?.value

        if(hasMoreThanOneKey) {
            let tmpValue = null

            splitedKeys.forEach((key, i) => {
                if(!i) {
                    tmpValue = acl[key]
                } else if (i !== (splitedKeys.length - 1)) {
                    tmpValue = tmpValue[key]
                }
            })

            if(type && !isDelete) {
                if(!value || isDelete) {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = []
                } else {
                    tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                }
            } else {
                tmpValue[splitedKeys[splitedKeys.length - 1]] = value
            }
        } else {
            if(type && !isDelete) {
                if(!value) {
                    acl[key] = []
                } else {
                    if(!Array.isArray(acl[key])) acl[key] = []
                    acl[key].push(value)
                }
            } else {
                acl[key] = value
            }
        }

        setACL({ ...acl })
    }

    const handleRemoveParam = (value, endpoint) => {
        handleValue({ target: { value } }, `endpoints.${endpoint}.roles`, 'array', true)
        console.log(acl)
    }

    const handleRemoveParamSpecialRoles = (value) => {
        handleValue({ target: { value } }, 'special_roles', 'array', true)
    }

    const mountTab = (endpoint) => {
        return (
            <Form 
                layout={'vertical'}
                initialValues={{
                    ...acl
                }}
            >
                <Item label={
                    (<>
                        {'Permissões Comuns'}
                        <Tooltip placement="topLeft" title={`
                            O usuário PRECISA ter TODAS as permissões para conseguir acesso à API
                        `}>
                            <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                        </Tooltip>
                    </>)
                }>
                    <Input 
                        placeholder={'Digite o parâmetro exatamente como é para a comparação e dê enter'}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleValue(e, `endpoints.${endpoint}.roles`, 'array')
                                setRoleInputValue('')
                            }
                        }}
                        value={roleInputValue}
                        onChange={(e) => setRoleInputValue(e.target.value)}
                    />
                    <Select
                        mode="tags"
                        style={{
                            width: '100%',
                            marginTop: 8
                        }}
                        bordered={false}
                        allowClear
                        showArrow={false}
                        options={acl?.endpoints?.[endpoint]?.roles?.map(param => {
                            return {
                                label: param,
                                value: param
                            }
                        })} 
                        onClear={(e) => handleRemoveParam(e, endpoint)}
                        onChange={(e) => handleRemoveParam(e, endpoint)}
                        dropdownStyle={{ display: 'none' }} 
                        onInputKeyDown={(e) => e.preventDefault()}
                        value={acl?.endpoints?.[endpoint]?.roles}
                    />
                </Item>
            </Form>
        )
    }

    useEffect(() => {
        setTabs([
            {
                name: <Tag color="success">LIST | GET</Tag>,
                content: mountTab('list')
            },
            {
                name: <Tag color="success">GET</Tag>,
                content: mountTab('show')
            },
            {
                name: <Tag color="processing">POST</Tag>,
                content: mountTab('create')
            },
            {
                name: <Tag color="processing">PUT</Tag>,
                content: mountTab('update')
            },
            {
                name: <Tag color="error">DELETE</Tag>,
                content: mountTab('delete')
            }
        ])
    }, [roleInputValue, acl])

    useEffect(() => {
        if(editACL?._id) {
            setACL({ ...editACL })
        } else {
            setACL({
                'name': null,
                'endpoints': {
                    'list': {
                        'roles': []
                    },
                    'show': {
                        'roles': []
                    },
                    'create': {
                        'roles': []
                    },
                    'update': {
                        'roles': []
                    },
                    'delete': {
                        'roles': []
                    }
                },
                'special_roles': [],
            })
            setRoleInputValue('')
            setSpecialRolesInputValue('')
        }
    }, [editACL, open])

    return (
        <Modal
            title={title}
            centered
            open={open}
            onCancel={async () => {
                handleOpen(false)
            }}
            width={1000}
            onOk={async () => await handleSave()}
            okText="Salvar"
            cancelText="Cancelar"
            cancelButtonProps={{ type: 'text' }}
            okButtonProps={{ loading: loading }}
        >
            <Form 
                layout={'vertical'}
                initialValues={{
                    ...acl
                }}
            >
                <Item label="Nome">
                    <Input placeholder="Nome do ACL" onChange={(e) => handleValue(e, 'name')} value={acl?.name} />
                </Item>
                <Item label={(<>
                        {'Super permissões'}
                        <Tooltip placement="topLeft" title={`
                            Se o usuário possuir alguma das super permissões, ele consegue acesso à API, exemplo: admin, support
                        `}>
                            <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                        </Tooltip>
                    </>)}>
                    <Input 
                        placeholder={'Digite o parâmetro exatamente como é para a comparação e dê enter'}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleValue(e, 'special_roles', 'array')
                                setSpecialRolesInputValue('')
                            }
                        }}
                        value={specialRolesInputValue}
                        onChange={(e) => setSpecialRolesInputValue(e.target.value)}
                    />
                    <Select
                        mode="tags"
                        style={{
                            width: '100%',
                            marginTop: 8
                        }}
                        bordered={false}
                        allowClear
                        showArrow={false}
                        options={acl?.special_roles?.map(param => {
                            return {
                                label: param,
                                value: param
                            }
                        })} 
                        onClear={(e) => handleRemoveParamSpecialRoles(e)}
                        onChange={(e) => handleRemoveParamSpecialRoles(e)}
                        dropdownStyle={{ display: 'none' }} 
                        onInputKeyDown={(e) => e.preventDefault()}
                        value={acl?.special_roles}
                    />
                </Item>
            </Form>
            <Divider />
            <Row 
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}
            >
                <Col span={24}>
                    <Tabs
                        tabPosition={isMobile ? 'top' : 'left'}
                        items={tabs.map((tab, i) => {
                            const id = String(i);
                            return {
                                label: tab.name,
                                key: id,
                                children: tab.content
                            }
                        })}
                        onTabClick={() => setRoleInputValue('')}
                    />
                </Col>
            </Row>
        </Modal>
    )
} 