import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
    verifyPermission, 
    permissionsStatus 
} from '../../helpers/permissions'

const getClientAuthApp = async ({ limit = 500, offset = 0 }) => {
    if(!verifyPermission('authApps', permissionsStatus.list)) return []

    const response  = await API.get(URLS.auth_app.list(limit, offset))

    return response?.data
}

const updateClientAuthApp = async ({ authApp, id }) => {
    if(!verifyPermission('authApps', permissionsStatus.update)) return {}

    const { data: response, isError } = await API.put(URLS.auth_app.update(id), authApp)

    return response?.data || isError
}

const revokeOAuth2Token = async ({ env, token }) => {
    if(!verifyPermission('authApps', permissionsStatus.delete)) return {}

    const { data: response, isError } = await API.delete(URLS.auth_app.revokeOAuth2Token(env, token))

    return response?.data || isError
}

const AuthApp = {
    getClientAuthApp,
    updateClientAuthApp,
    revokeOAuth2Token
}

export default AuthApp