import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
    verifyPermission, 
    permissionsStatus 
} from '../../helpers/permissions'

const getClientKeys = async ({ limit = 500, offset = 0 }) => {
    if(!verifyPermission('keys', permissionsStatus.list)) return []

    const { data } = await API.get(URLS.keys.list(limit, offset))

    return data
}

const createClientKeys = async ({ keys }) => {
    if(!verifyPermission('keys', permissionsStatus.create)) return {}

    const { data: response, isError } = await API.post(URLS.keys.create, keys)

    return response?.data || isError
}

const updateClientKeys = async ({ keys, id }) => {
    if(!verifyPermission('keys', permissionsStatus.update)) return {}

    const { data: response, isError } = await API.put(URLS.keys.update(id), keys)

    return response?.data || isError
}

const Keys = {
    getClientKeys,
    createClientKeys,
    updateClientKeys
}

export default Keys