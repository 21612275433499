import { URLS } from '../../environment.js'
import axios from 'axios'

const api = axios.create({
    baseURL: (window.location.href.match(/sandbox/) || window.location.href.match(/localhost/)) ? URLS.api.sandbox_update : URLS.api.production_update,
})

const verifySetupToken = async ({ token }) => {
    try {
        const { data } = await api.get(URLS.setup.verifyToken(token), {
            headers: {
                'Content-Type': 'application/json'
            }
        })

        return data?.data
    } catch (error) {
        return {
            is_valid: false
        }        
    }
}

const create = async ({ state }) => {
    try {
        const { data } = await api.post(URLS.setup.create, state, {
            headers: {
                'Content-Type': 'application/json'
            }
        })

        return data?.data
    } catch (error) {
        return {
            is_valid: false
        }        
    }
}

const Setup = {
    verifySetupToken,
    create
}

export default Setup