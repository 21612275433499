import React, { useEffect, useState } from 'react'
import { 
    Input, 
    Card,
    Col,
    Row,
    Avatar,
    Typography,
    Form,
    Button
} from 'antd'
import {
    UserOutlined
} 
from '@ant-design/icons'
import {
    PrincipalContainer
} from '../../containers'
import { Notification } from '../../components'
import { User } from '../../modules'

const { Text } = Typography,
    { Item } = Form

export default function ProfilePage ({ setCurrentPage, user }) {
    const [objData, setObjData] = useState({
        password: '',
        repeat_password: '',
        current_password: ''
    })

    const handleForm = async () => {
        if(objData?.current_password?.length <= 0) {
            return Notification({
                description: `Digite a senha atual e tente novamente`,
                title: 'Atualizar Senha',
                type: 'warning'
            })
        }

        if(objData?.password != objData?.repeat_password) {
            return Notification({
                description: `As novas senhas não são iguais, verifique e tente novamente`,
                title: 'Atualizar Senha',
                type: 'warning'
            })
        }

        const data = await User.updateUser({ user: objData })

        if(data) {
            return Notification({
                description: `Senha atualizada com sucesso`,
                title: 'Atualizar Senha'
            })
        }

        Notification({ 
            description: `Senha atual não confere ou houve um erro ao atualizar a senha, tente novamente`,
            title: 'Atualizar Senha',
            type: 'warning'
        })
    }

    return (
        <PrincipalContainer menuItemSelected='5' setCurrentPage={setCurrentPage} content={
            <>
                <Typography.Title level={2} style={{ textAlign: 'left' }}>
                    Perfil
                </Typography.Title>
                <Row>
                    <Col style={{ overflow: 'scroll' }} span={12} offset={6}>
                        <Card 
                            title={<><Avatar size={100} style={{ margin: 10, float: 'left' }} src={user?.photo} icon={<UserOutlined />} />
                            <Text style={{ marginTop: 40, float: 'left', fontSize: '1.5rem' }}>
                                {user?.name}
                            </Text></>
                            }
                        >
                            <Form 
                                layout={'vertical'}
                            >
                                {
                                    (window.location.href.match(/sandbox/)?.length 
                                    || window.location.href.match(/localhost/)?.length) && (
                                        <Item label="Token atual para utilizar com client-api sandbox">
                                            <Input value={localStorage.getItem('@token')} type={'text'} />
                                        </Item>
                                    )
                                }
                                <Item label="Senha atual">
                                    <Input.Password placeholder='Digite a senha atual' onChange={(e) => {
                                        const value = e.target.value
                                        objData.current_password = value
                                        setObjData({ ...objData })
                                    }} type={'password'} />
                                </Item>
                                <Item label="Senha nova">
                                    <Input.Password placeholder='Digite a senha nova' onChange={(e) => {
                                        const value = e.target.value
                                        objData.password = value
                                        setObjData({ ...objData })
                                    }} type={'password'} />
                                </Item>
                                <Item label="Repita a senha nova">
                                    <Input.Password placeholder='Repita a senha nova' onChange={(e) => {
                                        const value = e.target.value
                                        objData.repeat_password = value
                                        setObjData({ ...objData })
                                    }} type={'password'} />
                                </Item>
                            </Form>
                            <Button type="primary" style={{ float: 'right' }} onClick={() => {
                                handleForm()
                            }}>
                                Salvar
                            </Button>
                        </Card>
                    </Col>
                </Row>
            </>
        } />
    )
    
}