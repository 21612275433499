import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
  verifyPermission, 
  permissionsStatus 
} from '../../helpers/permissions'

const getUserTransactions = async () => {
  if(!verifyPermission('transaction', permissionsStatus.list)) return []

  const { data: response } = await API.get(URLS.transactions.list(500, 0))

  return response?.data
}

const UserTransactions = {
    getUserTransactions
}

export default UserTransactions