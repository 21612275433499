import React from "react"
import { 
    Result,
    Button,
    Typography,
    Popconfirm
} from 'antd'
import { 
    SmileOutlined
} from '@ant-design/icons'

export default function PageFourSetup ({ handlePage = () => {}, token, state }) {
    const handleBackLogin = () => {
        handlePage(5)
    }

    return (
        <>
            <Result 
                icon={<SmileOutlined />}
                title="Quase lá... Copie e cole em um lugar seguro:"
                children={<>
                    <Typography.Text>
                        <Typography.Title level={2}>
                            Seu Token
                        </Typography.Title>
                        <Typography.Paragraph>
                            {token} 
                        </Typography.Paragraph>
                    </Typography.Text>
                    <Typography.Text>
                        <Typography.Title level={2}>
                            Sua Private Key
                        </Typography.Title>
                        <Typography.Paragraph>
                            {state.private_key} 
                        </Typography.Paragraph>
                    </Typography.Text>
                </>}
            />
            <Popconfirm
                title="Ir para o login"
                description="Você copiou e armazenou o token e a private key em um lugar seguro?"
                onConfirm={handleBackLogin}
                okText="Sim"
                cancelText="Não"
            >
                <Button style={{ display: 'block', margin: '0 auto', marginTop: -15 }} type="primary">Entrar</Button>
            </Popconfirm>
        </>
    )
} 