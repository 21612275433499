import React, { useState } from "react"
import { 
    Typography,
    Form,
    Input,
    Col,
    Button
} from 'antd'
import { Notification } from "../../components"

const { Item } = Form

export default function PageTwoSetup ({ state = {}, handleState = () => {}, handlePage = () => {} }) {
    const [componentState, setComponentState] = useState({
        name: '',
        cnpj_cpf: '',
        phone: '',
        address: ''
    })

    const handleValue = (e, key) => {
        const value = (key == 'phone' || key == 'cnpj_cpf') ? e.target.value.replace(/[^a-zA-Z0-9]/g, '').replace(' ', '') : e.target.value
        
        if(key == 'phone' || key == 'cnpj_cpf') {
            const isNumeric = Number(value) ?? null

            if((!isNumeric && isNumeric != 0) || value.length > 14) {
                return Notification({
                    type: 'warning',
                    description: `O campo ${key == 'cnpj_cpf' ? key.replace('_', ' ') : 'telefone'} suporta apenas números e máximo de 14 caracteres`
                })
            }   
        }   

        componentState[key] = value 

        setComponentState({ ...componentState })
    }

    const handleContinue = () => {
        let isOK = true,
            errorsBag = []

        Object.keys(componentState).forEach(key => {
            const value = componentState[key]

            switch (key) {
                case 'name':
                    isOK = value?.length >= 1 && value?.length <= 20
                    if(!isOK) errorsBag.push(<p>O nome deve ter entre 1 e 20 caracteres e é obrigatório!</p>)
                    break
                case 'cnpj_cpf':
                    isOK = value?.length >= 11 && value?.length <= 14
                    if(!isOK) errorsBag.push(<p>O CPF ou CNPJ é obrigatório!</p>)
                    break
                case 'phone':
                    isOK = value?.length == 11 || value?.length == 10
                    if(!isOK) errorsBag.push(<p>O telefone é obrigatório!</p>)
                    break
                case 'address':
                    isOK = value?.length >= 5
                    if(!isOK) errorsBag.push(<p>O endereço é obrigatório!</p>)
                    break
            }
        })

        if(!isOK) return Notification({
            title: 'Continuar',
            type: 'warning',
            description: errorsBag
        })

        state = {
            ...state,
            ...componentState
        }

        handleState({ ...state })
        handlePage(3)
    }

    return (
        <>
            <Typography.Title level={3} style={{ textAlign: 'center' }}>
                {'Preencha as informações abaixo'}
            </Typography.Title>
            <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Form layout="vertical" style={{ width: '100%' }}>
                    <Item label={'Razão Social/Nome'}>
                        <Input 
                            value={componentState.name}
                            onChange={(e) => handleValue(e, 'name')}
                        />
                    </Item>
                    <Item label={'CNPJ/CPF'}>
                        <Input 
                            value={componentState.cnpj_cpf?.length > 0 ? (componentState.cnpj_cpf?.length == 11 ? componentState.cnpj_cpf.replace(/^((\d{3})(\d{3})(\d{3})(\d{2}))*$/, "$2.$3.$4-$5") : componentState.cnpj_cpf.replace(/^((\d{2})(\d{3})(\d{3})(\d{4})(\d{2}))*$/, "$2.$3.$4/$5-$6")) : ''}
                            onChange={(e) => handleValue(e, 'cnpj_cpf')}
                        />
                    </Item>
                    <Item label={'Telefone'}>
                        <Input 
                            value={componentState.phone?.length > 0 ? (componentState.phone?.length == 11 ? componentState.phone.replace(/^((\d{2})(\d{5})(\d{4}))*$/, "($2) $3-$4") : (componentState.phone?.length == 10 ? componentState.phone.replace(/^((\d{2})(\d{4})(\d{4}))*$/, "($2) $3-$4") : componentState.phone)) : ''}
                            style={{ width: '100%' }} 
                            onChange={(e) => handleValue(e, 'phone')}
                        />
                    </Item>
                    <Item label={'Endereço'}>
                        <Input 
                            value={componentState.address}
                            onChange={(e) => handleValue(e, 'address')}
                        />
                    </Item>
                </Form>
            </Col>
            <div 
                style={{
                    float: 'right'
                }}
            >
                <Button 
                    style={{ 
                        float: 'right',
                        marginRight: 17
                    }} 
                    onClick={handleContinue}
                    shape="round" size={'large'}
                >
                    Continuar
                </Button>
            </div>
        </>
    )
} 