import React, { useEffect, useState } from "react"
import { 
    Modal,
    Input,
    Form
} from 'antd'
import { Notification } from "../.."
import { Apps } from "../../../modules"

const { Item } = Form

export default function DestroyAppModal ({ title = '', open = false, handleOpen = () => {}, id = null, name = null, mutating = () => {} }) {
    const [appName, setAppName] = useState(''),
        [disabled, setDisabled] = useState(true),
        [loading, setLoading] = useState(false)

    const handleDestroy = async () => {
        setLoading(true)
        const data = await Apps.deleteClientApp({ id })

        if(data) {
            handleOpen(false)
            handleNotificationSuccess()
            mutating()
        } 
        
        setLoading(false)
    }

    const handleNotificationError = () => {
        Notification({ 
            description: `Houve um erro ao excluir a API, tente novamente`,
            title: 'API',
            type: 'warning'
        })
    }

    const handleNotificationSuccess = () => {
        Notification({ 
            description: `API excluída com sucesso`,
            title: 'API'
        })
    }

    const handleValue = (e) => {
        setAppName(e.target.value)
    }

    useEffect(() => {
        if(appName !== name) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [appName])

    useEffect(() => {
        setAppName('')
    }, [open])

    return (
        <Modal
            title={title}
            centered
            open={open}
            onCancel={() => handleOpen(false)}
            width={1000}
            onOk={async () => await handleDestroy()}
            okText="Excluir"
            cancelText="Cancelar"
            cancelButtonProps={{ type: 'text' }}
            okButtonProps={{ danger: true, disabled: disabled, loading: loading }}
        >
            <Form 
                layout={'vertical'}
                initialValues={{
                    appName
                }}
            >
                <Item>
                    <Input placeholder="Digite o nome da API para prosseguir com a exclusão" onChange={(e) => handleValue(e)} value={appName} />
                </Item>
            </Form>
        </Modal>
    )
} 