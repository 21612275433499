import React, { useEffect, useState } from "react"
import { 
    Modal,
    Popconfirm,
    Col,
    Row,
    Button,
    Typography,
    Select, 
    Tag,
    Divider,
    Tooltip,
    Input,
    Form
} from 'antd'
import {
    InfoCircleOutlined
} from '@ant-design/icons'
import { Notification } from "../.."
import { 
    Migration
} from "../../../modules"
import { Editor } from '@monaco-editor/react'
import { useSWRConfig } from "swr"

const { Item } = Form

export default function MigrationModal ({ open = false, migrationId = null, handleOpen = () => {}, migrationData = {}, mutating = () => {} }) {
    const { cache } = useSWRConfig()

    const [migration, setMigration] = useState({}),
    [loading, setLoading] = useState(false),
    [envsToExecute, setEnvsToExecute] = useState([]),
    [openPopup, setOpenPopup] = useState(false),
    [editorSQL, setEditorSQL] = useState(''),
    isEditModal = migrationId != undefined

    const { data: connectionsData, isLoading: isLoadingConnections } = cache.get('@connections') ?? { data: [] }

    const handleSave = async () => {
        setLoading(true)

        if(!migration?.name?.length) {
            setLoading(false)

            return Notification({ 
                description: `O campo Nome é obrigatório`,
                title: 'Salvar',
                type: 'warning'
            })
        }

        if(isEditModal) {
            await Migration.updateClientMigration({ migration, id: migrationId })
        } else {
            migration.sql = editorSQL
            await Migration.createClientMigration({ migration })
        }
        
        setLoading(false)
        handleOpen(false)
        handleNotificationSuccess()
        setEnvsToExecute([])
        await mutating()
    }

    const handleExecute = async () => {
        setOpenPopup(false)
        setLoading(true)

        if(isEditModal) {
            await Migration.executeClientMigration({ executeData: {
                executeIn: envsToExecute
            }, id: migrationId })
        }
        
        setLoading(false)
        handleOpen(false)
        handleNotificationSuccess(true)
        setMigration({})
        setEnvsToExecute([])
        await mutating()
    }

    const handleEnvsToExecute = (envs) => {
        setEnvsToExecute([ ...envs ])
    }

    const handleValue = (e, key, type = null, isDelete = false) => {
        const splitedKeys = key.split('.'),
            hasMoreThanOneKey = splitedKeys.length > 1

        const value = e?.target?.value

        if(hasMoreThanOneKey) {
            let tmpValue = null

            splitedKeys.forEach((key, i) => {
                if(!i) {
                    tmpValue = migration[key]
                } else if (i !== (splitedKeys.length - 1)) {
                    tmpValue = tmpValue[key]
                }
            })

            if(type && !isDelete) {
                const r = new RegExp(`array.string.\*`, 'g')

                if(type.match(r)?.length) {
                    const index = parseInt(type.split('.')[2])
                    let hasIndex = typeof tmpValue[splitedKeys[splitedKeys.length - 1]][index] == 'string'

                    if(!hasIndex) tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                    else {
                        let newValue = hasIndex ? tmpValue[splitedKeys[splitedKeys.length - 1]][index] : ''
                        
                        newValue = !value ? '': value
    
                        tmpValue[splitedKeys[splitedKeys.length - 1]][index] = newValue
                    }
                } else if(!value || isDelete) {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = []
                } else {
                    tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                }
            } else {
                tmpValue[splitedKeys[splitedKeys.length - 1]] = value
            }
        } else {
            migration[key] = value
        }

        setMigration({ ...migration })
    }

    const handleNotificationSuccess = (isExecute = false) => {
        if(isExecute) {
            return Notification({ 
                description: `Solicitação de execução de Migration efetuada com sucesso`,
                title: 'Migrations'
            })
        }

        Notification({ 
            description: `Migration criada com sucesso`,
            title: 'Migrations'
        })
    }

    useEffect(() => {
        setEnvsToExecute([])
        setOpenPopup(false)

        if(migrationData?._id) {
            setEditorSQL(migrationData?.sql || '')
            setMigration({ ...migrationData })
        } else if (!isEditModal && !migration?.name?.length) {
            setEditorSQL('')
            setMigration({
                'name': '',
                'migration_description': '',
                'connection_id': null,
                'sql': ''
            })
        }
    }, [migrationId])

    const modalBody = () => {
        const tagRender = (props) => {
            const { label, value, closable, onClose } = props
            const onPreventMouseDown = (event) => {
              event.preventDefault()
              event.stopPropagation()
            }

            let color = 'primary'

            switch (value) {
                case 'dev':
                    color = 'green'
                    break
                case 'sdb':
                    color = 'purple'
                    break
                case 'hml':
                    color = 'volcano'
                    break;
                case 'prd':
                    color = 'red'
                    break
            }

            return (
                <Tag
                    color={color}
                    onMouseDown={onPreventMouseDown}
                    closable={closable}
                    onClose={onClose}
                    style={{
                        marginRight: 3
                    }}
                >
                    {label}
                </Tag>
            )
        }

        const options = [
            {
                label: 'desenvolvimento',
                value: 'dev'
            },
            {
                label: 'sandbox',
                value: 'sdb'
            },
            {
                label: 'homologação',
                value: 'hml'
            },
            {
                label: 'produção',
                value: 'prd'
            },
        ]

        return (
            <>
                {isEditModal && (
                    <>
                        <Divider orientation="left">
                            {'Ambientes'}
                            <Tooltip placement="topLeft" title={`
                                Defina os ambientes em que a migration será executada
                                `}>
                                <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                            </Tooltip>
                        </Divider>
                        <Select
                            mode="multiple"
                            tagRender={tagRender}
                            defaultValue={envsToExecute}
                            value={options.filter(opt => migration?.environment?.[opt.value]?.status != 1)?.length == 4 && options.filter(opt => migration?.environment?.[opt.value]?.status != 1) || envsToExecute}
                            style={{
                                width: '100%',
                            }}
                            allowClear
                            disabled={(options.filter(opt => migration?.environment?.[opt.value]?.status == 1)?.length > 0) ? false : true}
                            options={options.filter(opt => migration?.environment?.[opt.value]?.status == 1)}
                            onClear={handleEnvsToExecute}
                            onChange={handleEnvsToExecute}
                        />
                        {migration?.logs?.length && (
                            <>
                                <Divider />
                                <Typography.Title style={{ marginTop: 20 }} level={3}>Logs</Typography.Title>
                                <pre style={{ 
                                    padding: 10, 
                                    border: '1px solid #ccc',
                                    borderRadius: 5
                                }}>
                                    {migration?.logs?.map(log => {
                                        const success = log.match(/SUCESSO/),
                                            error = log.match(/ERRO/)

                                        return (
                                            <>
                                                <Typography.Paragraph>
                                                    {(success || error) && tagRender({
                                                        label: log,
                                                        value: success && 'dev' || error && 'prd'
                                                    }) || log}
                                                </Typography.Paragraph>
                                            </>
                                        )
                                    })}
                                </pre>
                            </>
                        ) || <></>}
                    </>
                )}
            </>
        )
    }

    const modalFooter = () => {
        let obj = {
            okText: 'Salvar',
            okButtonProps: {
                onClick: async () => await handleSave(),
                disabled: !migration?.status != 1
            },
            cancelText: 'Cancelar',
            cancelButtonProps: {
                text: 'Cancelar',
                type: 'text', 
                onClick: () => {
                    setLoading(false)
                    handleOpen(false)
                } 
            }
        }

        if(isEditModal) {
            const disableButton = envsToExecute?.length == 0

            obj = {
                footer: <div style={{ display: 'inline-block' }}>
                    <Button type={"text"} onClick={() => {
                        setLoading(false)
                        handleOpen(false)
                    }}>Cancelar</Button>
                    <Popconfirm
                        open={openPopup}
                        title="Executar migration"
                        description="Tem certeza que deseja executar a migration nos ambientes selecionados?"
                        onConfirm={async () => await handleExecute()}
                        onCancel={() => setOpenPopup(false)}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button type="primary" onClick={() => {
                            if(!disableButton) {
                                setOpenPopup(true)
                            }
                        }} disabled={disableButton}>Executar</Button>
                    </Popconfirm>
                </div>
            }
        }

        return {
            ...obj
        }
    }

    return (
        <Modal
            title={`${isEditModal ? `#${migration._id} - ${migration?.migration_description}` : 'Criando nova migration'}`}
            centered
            open={open}
            width={1000}
            {...modalFooter()}
            onCancel={() => {
                setLoading(false)
                setEnvsToExecute([])
                handleOpen(false)
            }}
        >
            <Row 
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}
            >
                <Col span={24}>
                    <Form layout="vertical">
                        <Row 
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                        >
                            <Col className="gutter-row" span={16}>
                                <Item label="Nome">
                                    <Input placeholder="Nome da migration" disabled={isEditModal} onChange={(e) => handleValue(e, 'name')} value={migration?.name}  />
                                </Item>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <Item label="Conexão">
                                    <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Selecionar conexão"
                                        loading={isLoadingConnections}
                                        options={connectionsData?.length > 0 && connectionsData?.map(conn => {
                                            return {
                                                label: conn.name,
                                                value: conn._id
                                            }
                                        }) || []} 
                                        onChange={(e) => handleValue({ target: { value: e }}, 'connection_id')}
                                        onInputKeyDown={(e) => e.preventDefault()}
                                        value={migration?.connection_id || null}
                                        disabled={isEditModal}
                                    />
                                </Item>
                            </Col>
                            {!isEditModal && (
                                <Col className="gutter-row" span={24}>
                                    <Item label="Descrição">
                                        <Input.TextArea placeholder="Descrição da migration" disabled={isEditModal} onChange={(e) => handleValue(e, 'migration_description')} value={migration?.migration_description}  />
                                    </Item>
                                </Col>
                            )}
                        </Row>
                    </Form>
                    <Divider orientation="left">
                        {'SQL'}
                        <Tooltip placement="topLeft" title={`
                            Digite os códigos SQL para execução, não é possível editar após salvar
                        `}>
                            <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                        </Tooltip>
                    </Divider>
                    <Editor
                        language="mysql"
                        value={editorSQL}
                        theme='vs-dark'
                        height="50vh" 
                        onChange={(newValue) => {
                            setEditorSQL(newValue)
                        }}
                        options={{
                            autoIndent: "full",
                            readOnly: isEditModal ? true : false
                        }}
                    />
                </Col>
                <Col span={24}>
                    {modalBody()}
                </Col>
            </Row>
        </Modal>
    )
} 