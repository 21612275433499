import React, { useState } from "react"
import { 
    Typography,
    Input,
    Col
} from 'antd'
import { Setup } from '../../modules'
import { Notification } from "../../components"

export default function PageOneSetup ({ state = {}, handleState = () => {}, handlePage = () => {} }) {
    const [serial, setSerial] = useState({
        part_one: '',
        part_two: '',
        part_three: ''
    }),
    [inputsRef, setInputsRef] = useState({
        part_one: '',
        part_two: '',
        part_three: ''
    }) 

    const handleVerifyToken = async () => {
        state.serial = `${serial.part_one}-${serial.part_two}-${serial.part_three}`
        handleState({ ...state })

        const response = await Setup.verifySetupToken({ token: state.serial })

        if(!response?.is_valid) {
            serial.part_one = ''
            serial.part_two = ''
            serial.part_three = ''

            setSerial({ ...serial })

            inputsRef.part_one.focus()

            return Notification({
                title: 'Serial',
                type: 'warning',
                description: `O serial ${state.serial} é inválido ou já está em uso, verifique e tente novamente.`
            })
        }

        handlePage(2)
    }

    return (
        <>
            <Typography.Title level={3} style={{ textAlign: 'center' }}>
                {'Digite o serial de ativação'}
            </Typography.Title>
            <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Input 
                    value={serial.part_one}
                    ref={(input) => {
                        if(inputsRef.part_one) return

                        inputsRef.part_one = input
                        setInputsRef({ ...inputsRef })
                    }}
                    onKeyDown={(e) => {
                        const isAllowedChar = (e.code.match(/Key/) || e.code.match(/Backspace/) || e.code.match(/Digit/))?.length > 0
                        
                        if(!isAllowedChar) return

                        if(e.code == 'Backspace') {
                            if(serial.part_one.length) {
                                serial.part_one = serial.part_one.slice(0, serial.part_one.length - 1)
                                return setSerial({ ...serial })
                            }

                            return
                        }

                        serial.part_one += (e.code.split(/Key*/)?.[1] || e.code.split(/Digit*/)?.[1])
                        setSerial({ ...serial })

                        if(serial.part_one?.length == 4) {
                            inputsRef.part_two.focus()
                        }
                    }} 
                    style={{ width: '4.5rem', marginRight: 10 }}
                />
                -
                <Input
                    value={serial.part_two}
                    ref={(input) => {
                        if(inputsRef.part_two) return

                        inputsRef.part_two = input
                        setInputsRef({ ...inputsRef })
                    }} 
                    onKeyDown={(e) => {
                        const isAllowedChar = (e.code.match(/Key/) || e.code.match(/Backspace/) || e.code.match(/Digit/))?.length > 0

                        if(!isAllowedChar) return

                        if(e.code == 'Backspace') {
                            if(serial.part_two.length) {
                                serial.part_two = serial.part_two.slice(0, serial.part_two.length - 1)
                                return setSerial({ ...serial })
                            }

                            return inputsRef.part_one.focus()
                        }

                        serial.part_two += (e.code.split(/Key*/)?.[1] || e.code.split(/Digit*/)?.[1])
                        setSerial({ ...serial })

                        if(serial.part_two?.length == 4) {
                            inputsRef.part_three.focus()
                        }
                    }} 
                    style={{ width: '4.5rem', marginLeft: 10, marginRight: 10 }}
                />
                -
                <Input 
                    value={serial.part_three}
                    ref={(input) => {
                        if(inputsRef.part_three) return

                        inputsRef.part_three = input
                        setInputsRef({ ...inputsRef })
                    }}
                    onKeyDown={async (e) => {
                        const isAllowedChar = (e.code.match(/Key/) || e.code.match(/Backspace/) || e.code.match(/Digit/))?.length > 0

                        if(!isAllowedChar) return

                        if(e.code == 'Backspace') {
                            if(serial.part_three.length) {
                                serial.part_three = serial.part_three.slice(0, serial.part_three.length - 1)
                                return setSerial({ ...serial })
                            }

                            return inputsRef.part_two.focus()
                        }

                        serial.part_three += (e.code.split(/Key*/)?.[1] || e.code.split(/Digit*/)?.[1])
                        setSerial({ ...serial })

                        if(serial.part_three?.length == 4) {
                            await handleVerifyToken()
                        }
                    }} 
                    style={{ width: '4.5rem', marginLeft: 10 }}
                />
            </Col>
        </>
    )
} 