import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
    verifyPermission, 
    permissionsStatus 
} from '../../helpers/permissions'

const getClientWorkers = async ({ limit = 500, offset = 0, group = null }) => {
    if(!verifyPermission('workers', permissionsStatus.list)) return []

    const response  = await API.get(URLS.workers.list(limit, offset, group))

    return response?.data
}

const createClientWorker = async ({ worker }) => {
    if(!verifyPermission('workers', permissionsStatus.create)) return {}

    const { data: response, isError } = await API.post(URLS.workers.create, worker)

    return response?.data || isError
}

const updateClientWorker = async ({ worker, id }) => {
    if(!verifyPermission('workers', permissionsStatus.update)) return {}

    const { data: response, isError } = await API.put(URLS.workers.update(id), worker)

    return response?.data || isError
}

const deleteClientWorker = async ({ id }) => {
    if(!verifyPermission('workers', permissionsStatus.delete)) return {}

    const { data: response, isError } = await API.delete(URLS.workers.delete(id))

    return response?.data || isError
}

const Worker = {
    getClientWorkers,
    createClientWorker,
    updateClientWorker,
    deleteClientWorker
}

export default Worker