import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
    verifyPermission, 
    permissionsStatus 
} from '../../helpers/permissions'

const getUsers = async ({ limit = 500, offset = 0 }) => {
    if(!verifyPermission('users', permissionsStatus.list)) return []

    const { data } = await API.get(URLS.users.list(limit, offset))

    return data
}

const createUser = async ({ user }) => {
    if(!verifyPermission('users', permissionsStatus.create)) return {}

    const { data: response, isError } = await API.post(URLS.users.create, user)

    return isError || response?.data
}

const updateUser = async ({ user, id }) => {
    if(!verifyPermission('users', permissionsStatus.update)) return {}

    const { data: response, isError } = await API.put(URLS.users.update(id), user)

    return isError || response?.data
}

const deleteUser = async ({ id }) => {
    if(!verifyPermission('users', permissionsStatus.delete)) return {}

    const { data: response, isError } = await API.delete(URLS.users.delete(id))

    return isError || response?.data
}

const verifyEmail = async ({ email }) => {
    const { data } = await API.get(URLS.users.verifyEmail(email))

    return data
}

const User = {
    getUsers,
    createUser,
    updateUser,
    deleteUser,
    verifyEmail
}

export default User