import {
    HomePage,
    LoginPage,
    ConnectionsPage,
    AppsPage,
    GroupsPage,
    ACLPage,
    BillingPage,
    ProfilePage,
    GetStartedPage,
    LandingPage,
    TransactionsPage,
    UsersPoliciesPage,
    UsersPage,
    SetupPage,
    UpdatePage,
    PullRequestPage,
    MigrationPage,
    LibsPage,
    KeysPage,
    WorkersPage,
    LogsPage,
    AuthPage
} from './pages'

const Router = {
    homePage: (props) => <HomePage {...props} />,
    loginPage: (props) => <LoginPage {...props} />,
    connectionsPage: (props) => <ConnectionsPage {...props} />,
    appsPage: (props) => <AppsPage {...props} />,
    groupsPage: (props) => <GroupsPage {...props} />,
    aclPage: (props) => <ACLPage {...props} />,
    billingPage: (props) => <BillingPage {...props} />,
    profilePage: (props) => <ProfilePage {...props} />,
    getStartedPage: (props) => <GetStartedPage {...props} />,
    landingPage: (props) => <LandingPage {...props} />,
    transactionsPage: (props) => <TransactionsPage {...props} />,
    usersPoliciesPage: (props) => <UsersPoliciesPage {...props} />,
    usersPage: (props) => <UsersPage {...props} />,
    setupPage: (props) => <SetupPage {...props} />,
    updatePage: (props) => <UpdatePage {...props} />,
    pullRequestPage: (props) => <PullRequestPage {...props} />,
    migrationPage: (props) => <MigrationPage {...props} />,
    libsPage: (props) => <LibsPage {...props} />,
    keysPage: (props) => <KeysPage {...props} />,
    workersPage: (props) => <WorkersPage {...props} />,
    logsPage: (props) => <LogsPage {...props} />,
    authPage: (props) => <AuthPage {...props} />,
}

export default Router