import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
    verifyPermission, 
    permissionsStatus 
} from '../../helpers/permissions'

const getClientAppLogs = async ({ appId }) => {
    if(!verifyPermission('logs', permissionsStatus.list)) return []

    const response  = await API.get(URLS.logs.list(appId))

    return response?.data
}

const Logs = {
    getClientAppLogs,
}

export default Logs