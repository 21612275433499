import React, { useEffect, useState } from 'react'
import { Table as AntdTable } from 'antd'

export default function Table ({ columns = [], tableData = [], isLoading = true, pagination = true }) {
    const [tableParams, setTableParams] = useState({
        pagination: {
          current: 1,
          pageSize: 10,
          position: ['bottomLeft'],
        }
    }),
    [data, setData] = useState(tableData),
    [loading, setLoading] = useState(isLoading)

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
          pagination,
          filters,
          ...sorter,
        })
    
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
          setData([]);
        }
    }

    useEffect(() => {
        setData(tableData.map(d => {
          // d.responsive = ['xs','sm', 'md', 'lg']
          return d
        }))
        setLoading(isLoading)
    }, [tableData, isLoading])

    return (
        <AntdTable
            columns={columns}
            dataSource={data}
            pagination={pagination && {
              defaultCurrent: 1,
              pageSizeOptions: [10, 25, 50],
              pageSize: 10,
              total: data?.length,
              
            } || false}
            loading={loading}
            onChange={handleTableChange}
        />
    )
}