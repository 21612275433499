import API from '../../services/api'
import { URLS } from '../../environment.js'

const getServerIP = async () => {
    const { data: response } = await API.get(URLS.server_config.ip)

    return response?.data
}

const Server = {
    getServerIP
}

export default Server