import React, { useEffect, useState } from 'react'
import { 
    Typography, 
    Space, 
    Button,
    Row,
    Col,
    Tooltip
} from 'antd'
import { useSWRConfig } from 'swr'
import { 
    Table,
    ACLModal,
    DestroyACLModal
} from '../../components'
import {
    InfoCircleOutlined
} from '@ant-design/icons'
import {
    PERMISSIONS
} from '../../environment'
import {
    PrincipalContainer
} from '../../containers'
import { ACL } from '../../modules'
import { 
    DeleteFilled,
    EditFilled
} from '@ant-design/icons'

const { Link } = Typography

export default function ACLPage ({ setCurrentPage, userPermissions }) {
    const { cache, mutate } = useSWRConfig()

    const [tableData, setTableData] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    [editData, setEditData] = useState({}),
    [createData, setCreateData] = useState({}),
    [destroyData, setDestroyData] = useState({}),
    [openEditModal, setOpenEditModal] = useState(false),
    [openCreateModal, setOpenCreateModal] = useState(false),
    [openDestroyModal, setOpenDestroyModal] = useState(false),
    tableColumns = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
        },
        {
            title: 'Ações',
            dataIndex: 'actions',
            key: '_id',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                <Space size="middle">
                    {userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                        <Link onClick={() => handleEditModal(row)} href='#'>
                            <EditFilled  style={{ fontSize: '16px' }} />
                        </Link>
                    )}
                    {userPermissions?.includes(PERMISSIONS.PERMISSION_DELETE) && (
                        <Link href='#' type='danger' onClick={() => handleDestroyModal(row)}>
                            <DeleteFilled style={{ fontSize: '16px', color: '#d9534f' }} />
                        </Link>
                    )}
                </Space>
            ),
        },
    ]

    const { data: aclData, isLoading: aclIsLoading } = cache.get('@acls') ?? { data: [] }

    const mutating = async () => {
        await mutate('@acls', () => ACL.getClientACLs({}))

        const newCache = cache.get('@acls')?.data ?? []

        setTableData(newCache?.map((acl, i) => {
            return {
                key: String(i),
                ...acl
            }
        }) || [])

        setEditData({})
        setDestroyData({})
        setCreateData({})

        setIsLoading(false)
    }

    const handleEditModal = (acl) => {
        setEditData({ ...acl })
    }

    const handleDestroyModal = (acl) => {
        setDestroyData({ ...acl })
    }

    const handleCreateModal = () => {
        setCreateData({ close: Math.random() })
    }

    const handleEditModalOpen = () => {
        return <ACLModal 
            title={`Editando ACL: ${editData?.name}`} 
            open={openEditModal} 
            handleOpen={setOpenEditModal} 
            editACL={editData} 
            mutating={mutating}
        />
    }

    const handleCreateModalOpen = () => {
        return <ACLModal 
            title={`Criando novo ACL`} 
            open={openCreateModal} 
            handleOpen={setOpenCreateModal} 
            editACL={{}} 
            mutating={mutating}
        />
    }

    const handleDestroyModalOpen = () => {
        return <DestroyACLModal
            title={`Excluir ACL`} 
            open={openDestroyModal} 
            handleOpen={setOpenDestroyModal} 
            id={destroyData._id}
            name={destroyData.name} 
            mutating={mutating}
        />
    }

    useEffect(() => {
        if(Object.keys(createData).length) setOpenCreateModal(true)
        else setOpenCreateModal(false)
    }, [createData])

    useEffect(() => {
        if(Object.keys(editData).length) {
            setCreateData({})
            setOpenEditModal(true)
        }
        else setOpenEditModal(false)
    }, [editData])

    useEffect(() => {
        if(Object.keys(destroyData).length) setOpenDestroyModal(true)
        else setOpenDestroyModal(false)
    }, [destroyData])

    useEffect(() => {
        setTableData(aclData?.map((acl, i) => {
            return {
                key: String(i),
                ...acl
            }
        }) || [])

        setIsLoading(false)
    }, [aclIsLoading])

    return (
        <>
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                    handleEditModalOpen()
                )
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                    handleCreateModalOpen()
                )
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_DELETE) && (
                    handleDestroyModalOpen()
                )
            }
            <PrincipalContainer menuItemSelected='3' setCurrentPage={setCurrentPage} content={
                <>
                    <Typography.Title level={2} style={{ textAlign: 'left' }}>
                        ACL
                        <Tooltip placement="topLeft" title={`O ACL (Access Control Level) exerce a função de proteger os endpoints de suas APIs em que o usuário não tem as devidas permissões para acessa-la`}>
                            <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                        </Tooltip>
                        {userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                            <Button style={{ float: 'right', top: 5 }} type="primary" shape="round" onClick={handleCreateModal}>+</Button>
                        )}
                    </Typography.Title>
                    <Row>
                        <Col span={24}>
                            <Table 
                                columns={tableColumns}
                                isLoading={isLoading}
                                tableData={tableData}
                            />
                        </Col>
                    </Row>
                </>
            } />
        </>
    )
}