import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
    verifyPermission, 
    permissionsStatus 
} from '../../helpers/permissions'

const getClientMigrations = async ({ limit = 500, offset = 0, conn = null }) => {
    if(!verifyPermission('migrations', permissionsStatus.list)) return []
    
    const { data } = await API.get(URLS.migration.list(limit, offset, conn))

    return data
}

const createClientMigration = async ({ migration }) => {
    if(!verifyPermission('migrations', permissionsStatus.create)) return {}

    const { data } = await API.post(URLS.migration.create, migration)

    return data
}

const updateClientMigration = async ({ migration, id }) => {
    if(!verifyPermission('migrations', permissionsStatus.update)) return {}

    const { data } = await API.put(URLS.migration.update(id), migration)

    return data
}

const deleteClientMigration = async ({ id }) => {
    if(!verifyPermission('migrations', permissionsStatus.delete)) return []

    const { data } = await API.delete(URLS.migration.delete(id))

    return data
}

const executeClientMigration = async ({ executeData, id }) => {
    if(!verifyPermission('migrations', permissionsStatus.create)) return {}

    const { data } = await API.post(URLS.migration.execute(id), executeData)

    return data
}

const Migration = {
    getClientMigrations,
    createClientMigration,
    updateClientMigration,
    deleteClientMigration,
    executeClientMigration
}

export default Migration