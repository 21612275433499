import React, { useEffect, useState, useRef } from "react"
import { 
    Row,
    Col,
    Layout,
    Button,
    Typography,
    List,
    Dropdown,
    Space,
    Collapse
} from "antd"
import {
    DownOutlined,
    LogoutOutlined
} from '@ant-design/icons'
import Router from "../../router"
import LOGO from '../../simple-rest-logo.svg'
import LOGOLIGHT from '../../logo-light.png'
import ADVANTAGESLIST from '../../advantages-list.png'
import N1 from '../../n1.svg'
import N2 from '../../n2.svg'
import N3 from '../../n3.svg'
import SHARE from '../../share.svg'
import DOWN from '../../down.svg'
import MONEY from '../../money.svg'
import MONEYEmoji from '../../money-emoji.svg'
import CLOCKEmoji from '../../clock-emoji.svg'
import FINGERUPEmoji from '../../finger-up-emoji.svg'
import LogoWithoutName from '../../shape-logo.png'
import WHATSAPP from '../../whatsapp.png'
import EMAIL from '../../email.png'
import NOTEAPPS from '../../note-apps.png'
import NOTECONEXOES from '../../note-conexoes.png'
import DOLLAR from '../../dollar.png'
import CHART from '../../grafico.png'

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    }
}

const { Panel } = Collapse

const { Header, Content } = Layout,
    { Title, Text } = Typography,
    pageStyles = {
        colors: {
            primary: '#2445F3',
            secondary: '#131A25',
            grey: '#E2EBF4'
        },
        h1: {
            fontSize: '3.5rem'
        },
        h1_mobile: {
            fontSize: '2.5rem'
        },
        p: {
            fontSize: '1rem',
            lineHeight: '1.8rem'
        },
    }

export default function LandingPage ({ setCurrentPage, setLogged }) {
    const [isMobile, setIsMobile] = useState(false),
        contactDivRef = useRef(null),
        [initialized, setInitialized] = useState(false)

    const handleLoginPage = () => {
        setCurrentPage(Router.loginPage({ setCurrentPage, setLogged }))
    }

    const sectionStyle = (backgroundColor = pageStyles.colors.grey, top = '10%', bottom = '10%') => {
        return {
            paddingTop: top,
            paddingBottom: bottom,
            backgroundColor
        }
    }

    const buttonStyle = {
        backgroundColor: pageStyles.colors.primary,
        color: '#fff',
        marginTop:'1rem'
    }

    const liStyle = {
        textAlign: 'center',
        display: 'block',
        width: '100%',
        ...pageStyles.p
    }

    const handleScrollToContactDiv = () => {
        return contactDivRef.current.scrollIntoView()
    }

    useEffect(() => {
        const handleResize = () => {
            const {
                width,
                height
            } = getWindowDimensions()

            if(width <= 768) setIsMobile(true)
            else setIsMobile(false)
        } 

        window.addEventListener('resize', handleResize)
        
        if(!initialized) {
            handleResize()
            return setInitialized(true)
        }

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <Layout style={{ backgroundColor: '#F1F5F9' }}>
            <Header style={{ backgroundColor: pageStyles.colors.grey, paddingInline: isMobile && '10px' || '50px' }} className="header">
                <img style={{ width: '11rem' }} src={LOGO} />
                <Dropdown menu={{
                    items: [
                        {
                            label: 'Início',
                            key: '1',
                            icon: <></>,
                            onClick: () => {
                                setCurrentPage(Router.landingPage({ setCurrentPage, setLogged }))
                            }
                        },
                        {
                            label: 'Manifesto',
                            key: '2',
                            icon: <></>,
                            onClick: () => {
                                setCurrentPage(Router.manifestoPage({ setCurrentPage, setLogged }))
                            }
                        },
                        {
                            label: 'Vamos conversar?',
                            key: '3',
                            icon: <></>,
                            onClick: () => {
                                handleScrollToContactDiv()
                            }
                        }
                    ]
                }}>
                    <Button style={{ marginLeft: 10 }} shape="round">
                        <Space>
                        Menu
                        <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
                <div style={{ float: 'right' }}>
                    <LogoutOutlined style={{ color: pageStyles.colors.primary, marginRight: '-10px' }} /><Button style={{ color: pageStyles.colors.primary }} type="link" size='large' onClick={() => handleLoginPage()}>Entrar</Button>
                </div>
            </Header>
            <section style={isMobile ? { ...sectionStyle(pageStyles.colors.grey, '25%', '10%') } : { ...sectionStyle(pageStyles.colors.grey, '5%') }}>
                <Content className="container">
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Col xs={24} xl={13}>
                            <p style={{ ...pageStyles.p }}>
                                Criação de APIs com rapidez e eficiência
                            </p>
                            <Title style={{ ...pageStyles?.[isMobile ? 'h1_mobile' : 'h1' ] }} level={1}>
                                Construa a sua API em questão de minutos
                            </Title>
                            <p style={{ ...pageStyles.p }}>
                                Automação de fluxo que atrasa, infraestrutura pronta, escalabilidade automática e suporte técnico personalizado. Invista na transformação digital e ganhe eficiência operacional.
                            </p>
                            <Button 
                                onClick={handleScrollToContactDiv}
                                style={buttonStyle}
                                shape='round'
                            >
                                {'Falar com um especialista'}
                            </Button>
                        </Col>
                        <Col xs={24} xl={11}>
                            {!isMobile && <img src={LogoWithoutName} />}
                        </Col>
                    </Row>
                </Content>
            </section>
            <section>
                <Content>
                    <Row
                        gutter={12}
                        >
                        <Col className="gutter-row" xs={24} xl={12}>
                            <div style={{ 
                                marginBottom: 10, 
                                marginTop: 10, 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                                display: 'flex',
                                 flexDirection: "column", 
                                 ...sectionStyle() 
                            }}>
                                <img src={DOLLAR} />
                                <Title level={2}>
                                    Tempo é dinheiro
                                </Title>
                                <List>
                                    <List.Item>
                                        <Text style={liStyle}>
                                            {'Elimine a necessidade dos desenvolvedores criarem APIs desde o início'}
                                        </Text>
                                    </List.Item>
                                    <List.Item>
                                        <Text style={liStyle}>
                                            {'Foque os esforços em outras demandas do produto'}
                                        </Text>
                                    </List.Item>
                                    <List.Item>
                                        <Text style={liStyle}>
                                            {'Apps de qualquer nível, com custo baixo'}
                                        </Text>
                                    </List.Item>
                                </List>
                            </div>
                        </Col>
                        <Col className="gutter-row" xs={24} xl={12}>
                            <div style={{ 
                                marginBottom: 10, 
                                marginTop: 10, 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                                display: 'flex',
                                flexDirection: "column", 
                                ...sectionStyle() 
                            }}>
                                <img src={CHART} />
                                <Title level={2}>
                                    Escalabilidade
                                </Title>
                                <List>
                                    <List.Item>
                                        <Text style={liStyle}>
                                            {'Use cache nas respostas da sua api'}
                                        </Text>
                                    </List.Item>
                                    <List.Item>
                                        <Text style={liStyle}>
                                            {'Escalável em qualquer demanda, automaticamente'}
                                        </Text>
                                    </List.Item>
                                    <List.Item>
                                        <Text style={liStyle}>
                                            {'Pague somente uma taxa mínima + o que consumir'}
                                        </Text>
                                    </List.Item>
                                </List>
                            </div>
                        </Col>
                        </Row>
                </Content>
            </section>
            <section style={sectionStyle(pageStyles.colors.primary)}>
                <Content className="container ">
                    <Title style={{ color: '#fff', textAlign: 'center' }} level={2}>
                        Suporte ao moderno protocolo HTTP/2
                    </Title>
                    <p style={{ color: '#fff', textAlign: 'center', fontSize: '1.2rem' }}>
                        com retrocompatibilidade ao HTTP/1 e HTTP/1.1
                    </p>
                    <Row gutter={8}>
                        <Col xs={24} xl={8}>
                            <div style={{ 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                                display: 'flex',
                                flexDirection: "column", 
                            }}>
                                <img src={N1} />
                                <Title style={{ color: '#fff' }} level={2}>
                                    Crie
                                </Title>
                                <p style={{ color: '#fff', textAlign: 'center', ...pageStyles.p }}>
                                    Crie APIs rapidamente por meio de nossa interface gráfica intuitiva.
                                </p>
                            </div>
                        </Col>
                        <Col xs={24} xl={8}>
                            <div style={{ 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                                display: 'flex',
                                flexDirection: "column", 
                            }}>
                                <img src={N2} />
                                <Title style={{ color: '#fff' }} level={2}>
                                    Publique
                                </Title>
                                <p style={{ color: '#fff', textAlign: 'center', ...pageStyles.p }}>
                                    Exponha suas APIs para a internet.
                                </p>
                            </div>
                        </Col>
                        <Col xs={24} xl={8}>
                            <div style={{ 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                                display: 'flex',
                                flexDirection: "column", 
                            }}>
                                <img src={N3} />
                                <Title style={{ color: '#fff' }} level={2}>
                                    Gerencie
                                </Title>
                                <p style={{ color: '#fff', textAlign: 'center', ...pageStyles.p }}>
                                    Gerenciamento completo do ciclo de vida da API.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </section>
            <section style={sectionStyle(pageStyles.colors.secondary)}>
                <Content className="container text-white">
                    <div style={{ 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        display: 'flex',
                        flexDirection: "column"
                    }}>
                        <Title style={{ color: '#fff', ...pageStyles.h1, textAlign: isMobile ? 'center' : 'inherit' }} level={1}>
                            No Simple REST é TUDO automatizado
                        </Title>  
                        <img src={ADVANTAGESLIST} />
                        <Title style={{ paddingTop:'10%', color: '#fff', ...pageStyles.h1, textAlign: isMobile ? 'center' : 'inherit' }} level={1}>
                            Exclusivo sistema Reverse Authentication
                        </Title>  
                        <p style={{ color: '#fff', textAlign: 'center', fontSize: '1.2rem' }}>
                            faça autenticação de seus clientes e gere uma sessão única compartilhada entre as APIs
                        </p>
                        <List>
                            <List.Item>
                                <Row style={{ alignItems: 'center', width: '100%', justifyContent:'center' }}>
                                    <Col xs={24} xl={6}>
                                        {isMobile && (
                                            <img style={{ display: 'block', margin: '0 auto' }} src={SHARE} />
                                        ) || (
                                            <img src={SHARE} />
                                        )}
                                    </Col>
                                    <Col xs={24} xl={14}>
                                        <Title style={{ color: '#fff', textAlign: isMobile ? 'center' : 'inherit' }} level={2}>
                                            Plug and Share
                                        </Title>  
                                        <p style={{ ...pageStyles.p, textAlign: isMobile ? 'center' : 'inherit' }}>
                                            "Plug" seu DB e compartilhe seus dados com o mundo ou somente com o seu sistema.
                                        </p>
                                    </Col>
                                </Row>
                            </List.Item>
                            <List.Item>
                            <Row style={{ alignItems: 'center', width: '100%', justifyContent:'center' }}>
                                    <Col xs={24} xl={6}>
                                        {isMobile && (
                                            <img style={{ display: 'block', margin: '0 auto' }} src={DOWN} />
                                        ) || (
                                            <img src={DOWN} />
                                        )}
                                    </Col>
                                    <Col xs={24} xl={14}>
                                        <Title style={{ color: '#fff', textAlign: isMobile ? 'center' : 'inherit' }} level={2}>
                                            Zero Downtime
                                        </Title>  
                                        <p style={{ ...pageStyles.p, textAlign: isMobile ? 'center' : 'inherit' }}>
                                            Criou ou atualizou... Já tá funcionando.
                                        </p>
                                    </Col>
                                </Row>
                            </List.Item>
                            <List.Item>
                            <Row style={{ alignItems: 'center', width: '100%', justifyContent:'center' }}>
                                    <Col xs={24} xl={6}>
                                        {isMobile && (
                                            <img style={{ display: 'block', margin: '0 auto' }} src={MONEY} />
                                        ) || (
                                            <img src={MONEY} />
                                        )}
                                    </Col>
                                    <Col xs={24} xl={14}>
                                        <Title style={{ color: '#fff', textAlign: isMobile ? 'center' : 'inherit' }} level={2}>
                                            Pagamento on-demand
                                        </Title>  
                                        <p style={{ ...pageStyles.p, textAlign: isMobile ? 'center' : 'inherit' }}>
                                            Pague somente uma taxa mínima + o que usar, com desconto progressivo.
                                        </p>
                                    </Col>
                                </Row>
                            </List.Item>
                        </List>
                    </div>
                </Content>
            </section>
           
            <section style={sectionStyle('inherit')}>
                <Content className="container">
                    <div style={{ 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        display: 'flex',
                        flexDirection: "column", 
                    }}>
                        <Title style={{ ...pageStyles.h1, textAlign: 'center' }} level={1}>
                            Foque apenas no que é importante: seu produto.                        
                        </Title>  
                        <Title level={2} style={{ textAlign: isMobile && 'center' || 'inherit' }}>
                            <img src={CLOCKEmoji} /> Time-saving, <img src={MONEYEmoji} /> money-making.                  
                        </Title>
                        <Row>
                            <Col style={{ padding: 50 }} xs={24} xl={24}>
                                <img style={{ display: 'block', margin: '0 auto' }} src={NOTEAPPS} />
                                <Title level={5} style={{ marginTop: 10, textAlign: 'center' }}>
                                    Gerencie suas APIs de maneira fácil, rápida e inteligente.
                                </Title>
                            </Col>
                            <Col style={{ padding: 50 }} xs={24} xl={24}>
                                <img style={{ display: 'block', margin: '0 auto' }} src={NOTECONEXOES} />
                                <Title level={5} style={{ marginTop: 10, textAlign: 'center' }}>
                                    Conecte o seu DB e crie micro serviços rapidamente.
                                </Title>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </section>
            <section style={sectionStyle(pageStyles.colors.grey)}>
                <Content className="container ">
                    <Row gutter={20}>
                        <Col xs={24} xl={6}>
                            <Title style={{ ...pageStyles.h1 }} level={1}>
                                <img src={FINGERUPEmoji} /> FAQ
                            </Title>
                            <p style={{ ...pageStyles.p }}>
                                Os 5 pontos de ouro a conhecer antes de iniciar com a Simplerest.
                            </p>
                        </Col>
                        <Col xs={24} xl={9}>
                            <Collapse ghost>
                                <Panel style={{ fontSize: '1.5rem' }} header="O que é uma API?" key="1">
                                    <p style={{ ...pageStyles.p }}>
                                        API é uma aplicação back-end de compartilhamento de dados, sem interface gráfica, por meio de requisições HTTP.
                                    </p>
                                </Panel>
                                <Panel style={{ fontSize: '1.5rem' }}  header="O que é gerenciamento de API?" key="2">
                                    <p style={{ ...pageStyles.p }}>
                                        Na Arquitetura de Micro Serviços, podemos ter diversas APIs compondo o funcionamento do sistema, tornando-se custoso e difícil de dar manutenção...
                                        com o Simple REST todas as suas APIs estão em um único lugar, podendo ser classificadas por grupos para uma boa organização. Altere, ative/desative, mude a resposta, mude a requisição, 
                                        faça diversas modificações em um único lugar.
                                    </p>
                                </Panel>
                                <Panel style={{ fontSize: '1.5rem' }} header="O que é uma plataforma de gerenciamento de API?" key="3">
                                    <p style={{ ...pageStyles.p }}>
                                        É um software IOT que te permite ter diversas APIs de maneira fácil e rápida, sem precisar se preocupar com infra ou dev.
                                    </p>
                                </Panel>
                            </Collapse>
                        </Col>
                        <Col xs={24} xl={9}>
                            <Collapse ghost>
                                <Panel style={{ fontSize: '1.5rem' }} header="Como começar a utilizar o Simple REST?" key="1">
                                    <p style={{ ...pageStyles.p }}>
                                        Entre em contato com pelo whatsapp ou email no rodapé dessa página. 
                                        Após isso, coletaremos alguns dados e liberaremos um usuário de teste para sua empresa testar nosso sandbox.
                                    </p>
                                </Panel>
                                <Panel style={{ fontSize: '1.5rem' }} header="Qual a garantia de segurança dos meus dados?" key="2">
                                    <p style={{ ...pageStyles.p }}>
                                        Nós não salvamos nenhuma informação/dados de requisições feitas para as APIs criadas em nossa plataforma, além de ter 
                                        um sistema de criptografia com senha individual que atualiza à cada 1h os hashs de conexões, ou seja, a cada 1h são geradas novas senhas e hashs para a segurança de suas conexões.
                                    </p>
                                </Panel>
                                <Panel style={{ fontSize: '1.5rem' }} header="O que é o reverse authentication?" key="3">
                                    <p style={{ ...pageStyles.p }}>
                                        Um sistema exclusivo de autenticação reversa que possibilita a criação de uma sessão de usuário no Simple REST, compartilhada entre todas as APIs que criar no sistema, e o melhor:
                                        possuímos SDK em PHP e NodeJS para fácil integração entre o seu sistema de autenticação e o Simple REST.
                                    </p>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Content>
            </section>

            <section style={sectionStyle(pageStyles.colors.primary)}>
                <Content ref={contactDivRef} className="container text-white">
                    <Row style={{ 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        flexDirection: "column",
                        textAlign: 'center'
                    }}>
                      <Col xs={24} xl={16}>

                        <p style={{ ...pageStyles.p }}>
                            Crie sua API em questão de minutos sem escrever código.
                        </p>
                        <Title style={{ ...pageStyles.h1 }} level={1}>
                            Para nós, o crescimento é uma mentalidade
                        </Title>  
                        <p style={{ ...pageStyles.p }}>
                            Escalabilidade, Arquitetura Distribuída e alta performance...
                            O SimpleREST surgiu para otimizar seu tempo e fazer você focar apenas na inovação do seu produto
                        </p>
                        <Row style={{ 
                        width:'100%',
                        justifyContent:'center'
                    }}>
                            <a href="https://api.whatsapp.com/send?phone=5511913034908" target={'BLANK'}>
                                <img width={140} height={125} src={WHATSAPP} />
                            </a>
                            <a href="emailto:contato@simplerest.com.br" target={'BLANK'}>
                                <img width={140} height={125} src={EMAIL} />
                            </a>
                        </Row>
                        </Col>
                    </Row>
                </Content>
            </section>
            <footer style={{ backgroundColor: pageStyles.colors.primary }}>
                <Content className="container text-white">
                    <Row  style={{ 
                        justifyContent: 'space-evenly', 
                        alignItems:'center',
                        marginTop: '1rem',
                        marginBottom: '1rem',

                    }}>
                        <img src={LOGOLIGHT} />

                        {/* <nav>
                            <a href="">Privacidade ·  </a>
                            <a href="">Termos ·  </a>
                            <a href="">Publicidade   </a>
                        </nav> */}
                        <span style={{ ...pageStyles.p }}>
                           Codetec
                            <span style={{  marginLeft: '5px' }}>
                            &copy; {(new Date()).getFullYear()}
                            </span>
                        </span>
                    </Row>
                </Content>
            </footer>
        </Layout>
    )
}