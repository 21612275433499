import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
    verifyPermission, 
    permissionsStatus 
} from '../../helpers/permissions'

const getClientApps = async ({ limit = 500, offset = 0, group = null }) => {
    if(!verifyPermission('apps', permissionsStatus.list)) return []

    const response  = await API.get(URLS.apps.list(limit, offset, group))

    return response?.data
}

const createClientApp = async ({ app }) => {
    if(!verifyPermission('apps', permissionsStatus.create)) return {}

    const { data: response, isError } = await API.post(URLS.apps.create, app)

    return response?.data || isError
}

const updateClientApp = async ({ app, id }) => {
    if(!verifyPermission('apps', permissionsStatus.update)) return {}

    const { data: response, isError } = await API.put(URLS.apps.update(id), app)

    return response?.data || isError
}

const deleteClientApp = async ({ id }) => {
    if(!verifyPermission('apps', permissionsStatus.delete)) return {}

    const { data: response, isError } = await API.delete(URLS.apps.delete(id))

    return response?.data || isError
}

const App = {
    getClientApps,
    createClientApp,
    updateClientApp,
    deleteClientApp
}

export default App