import React, { useEffect, useState } from 'react'
import { 
    Typography, 
    Space, 
    Button,
    Row,
    Col,
    Tooltip,
    Form,
    Input
} from 'antd'
import { useSWRConfig } from 'swr'
import {
    PERMISSIONS
} from '../../environment'
import { 
    Table,
    DestroyConnectionModal,
    MigrationLogsModal,
    Notification
} from '../../components'
import {
    PrincipalContainer
} from '../../containers'
import { Libs } from '../../modules'
import { 
    DeleteFilled,
    EditFilled,
    InfoCircleOutlined,
    EyeOutlined
} from '@ant-design/icons'
import Editor from '@monaco-editor/react'
import DOMPurify from 'dompurify'
import { marked } from 'marked'

const { Link } = Typography,
    { Item } = Form

export default function LibsPage ({ setCurrentPage, userPermissions }) {
    const { cache, mutate } = useSWRConfig()

    const [tableData, setTableData] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    [editData, setEditData] = useState({}),
    [createData, setCreateData] = useState({}),
    [destroyData, setDestroyData] = useState({}),
    [connectionIdLog, setConnectionLogId] = useState(null),
    [openCreateModal, setOpenCreateModal] = useState(false),
    [openDestroyModal, setOpenDestroyModal] = useState(false),
    [openConnectionLogModal, setOpenConnectionLogModal] = useState(null),
    [isSimpleLib, setIsSimpleLib] = useState(false),
    [codeDev, setCodeDev] = useState({
        properties: '',
        methods: ''
    }),
    [codeHml, setCodeHml] = useState({
        properties: '',
        methods: ''
    }),
    [codeSdb, setCodeSdb] = useState({
        properties: '',
        methods: ''
    }),
    [codePrd, setCodePrd] = useState({
        properties: '',
        methods: ''
    }),
    tableColumns = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
        },
        {
            title: 'Ações',
            dataIndex: 'actions',
            key: '_id',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                <Space size="middle">
                    {(userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && row.client_id != null) && (
                        <Link onClick={() => handleEditModal(row)} href='#'>
                            <EditFilled  style={{ fontSize: '16px' }} />
                        </Link>
                    )}
                    {(userPermissions?.includes(PERMISSIONS.PERMISSION_DELETE) && row.client_id != null) && (
                        <Link href='#' type='danger' onClick={() => handleDestroyModal(row)}>
                            <DeleteFilled style={{ fontSize: '16px', color: '#d9534f' }} />
                        </Link>
                    )}
                    {row.client_id == null && (
                        <Link href='#' type='primary' onClick={() => handleEditModal(row)}>
                            <EyeOutlined style={{ fontSize: '16px' }} />
                        </Link>
                    )}
                </Space>
            ),
        },
    ]

    

    const { data: libsData, isLoading: libsIsloading } = cache.get('@libs') || { data: [] }

    const mutating = async () => {
        await mutate('@libs', () => Libs.getClientLibs({}))

        const newCache = cache.get('@libs')?.data ?? []

        setTableData(newCache?.map((conn, i) => {
            return {
                key: String(i),
                ...conn
            }
        }) || [])

        setEditData({})
        setDestroyData({})
        setCreateData({})

        setIsLoading(false)
    }

    const handleEditModal = (lib) => {
        // Object.keys(lib?.envs).forEach(envKey => {
        //     let tmpMethods = '',
        //         tmpProperties = '',
        //         tmpCode = {
        //             properties: '',
        //             methods: ''
        //         }

        //     const methods = Object.keys(lib?.envs?.[envKey]?.code?.methods ?? {})

        //     if(methods?.length) {
        //         methods.forEach(key => {
        //             tmpMethods += `"${key}": ${lib?.envs?.[envKey]?.code?.methods[key]},\n\t`
        //         })
        //     }

        //     const props = Object.keys(lib?.envs?.[envKey]?.code?.properties ?? {})
    
        //     if(props?.length) {
        //         props.forEach(key => {
        //             if(Array.isArray(lib?.envs?.[envKey]?.code?.properties[key])) {
        //                 return tmpProperties += `"${key}": [${lib?.envs?.[envKey]?.code?.properties[key].map(prop => {
        //                     let value = prop
        
        //                     if(typeof prop == 'number') value = prop
        //                     else if (typeof prop == 'string') value = `"${prop}"`
                            
        //                     return value 
        //                 })}],`
        //             }
        
        //             tmpProperties += `"${key}": ${lib?.envs?.[envKey]?.code?.properties[key]},`
        //         })
        //     }
    
        //     tmpCode.methods = tmpMethods
        //     tmpCode.properties = tmpProperties

        //     switch (envKey) {
        //         case 'dev':
        //             setCodeDev({ ...tmpCode })
        //             break
        //         case 'hml':
        //             setCodeHml({ ...tmpCode })
        //             break
        //         case 'sdb':
        //             setCodeSdb({ ...tmpCode })
        //             break
        //         case 'prd':
        //             setCodePrd({ ...tmpCode })
        //             break
        //     }
        // })
        
        setEditData({ ...lib })
        setOpenCreateModal(true)
    }

    const handleDestroyModal = (lib) => {
        setDestroyData({ ...lib })
    }

    const handleCreateModal = () => {
        setCreateData({ 
            'name': '',
            'description': '',
            'readme': 'Para utilizar a lib, use a supervariável simplelib.{nomeClasse}, exemplo: new simplelib.ClasseTeste()',
            'version': '',
            'dependencies': [],
            'envs': {
                'dev': {
                    'code': {
                        'extends': '',
                        'properties': {},
                        'methods': {}
                    }
                },
                'hml': {
                    'code': {
                        'extends': '',
                        'properties': {},
                        'methods': {}
                    }
                },
                'sdb': {
                    'code': {
                        'extends': '',
                        'properties': {},
                        'methods': {}
                    }
                },
                'prd': {
                    'code': {
                        'extends': '',
                        'properties': {},
                        'methods': {}
                    }
                }
            }  
        })
    }

    const handleLogsModal = (connection) => {
        setConnectionLogId(connection?._id)
    }

    const handleCreateValue = (e, key, type = null, isDelete = false) => {
        const splitedKeys = key.split('.'),
            hasMoreThanOneKey = splitedKeys.length > 1

        const value = e?.target?.value

        if(hasMoreThanOneKey) {
            let tmpValue = null

            splitedKeys.forEach((key, i) => {
                if(!i) {
                    tmpValue = createData[key]
                } else if (i !== (splitedKeys.length - 1)) {
                    tmpValue = tmpValue[key]
                }
            })

            if(type && !isDelete) {
                const r = new RegExp(`array.string.\*`, 'g')

                if(type.match(r)?.length) {
                    const index = parseInt(type.split('.')[2])
                    let hasIndex = typeof tmpValue[splitedKeys[splitedKeys.length - 1]][index] == 'string'

                    if(!hasIndex) tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                    else {
                        let newValue = hasIndex ? tmpValue[splitedKeys[splitedKeys.length - 1]][index] : ''
                        
                        newValue = !value ? '': value
    
                        tmpValue[splitedKeys[splitedKeys.length - 1]][index] = newValue
                    }
                } else if(!value || isDelete) {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = []
                } else {
                    tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                }
            } else {
                tmpValue[splitedKeys[splitedKeys.length - 1]] = value
            }
        } else {
            createData[key] = value
        }

        setCreateData({ ...createData })
    }

    const handleCreateModalOpen = () => {
        return (
            <div style={{ backgroundColor: '#fff', height: '100vh', display: openCreateModal ? 'block' : 'none' }}>
                <Typography.Title level={2} style={{ textAlign: 'left' }}>
                    Visualizando lib 
                </Typography.Title>
                <Form layout={'vertical'}>
                    <Row 
                        gutter={{
                            xs: 8,
                            sm: 16,
                            md: 24,
                            lg: 32,
                        }}
                    >
                        <Col className="gutter-row" span={12}>
                            <Item label={'Nome'}>
                                <Input disabled={isSimpleLib} value={createData.name || editData.name} onChange={(e) => handleCreateValue(e, 'name')} />
                            </Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Item label={'Versão'}>
                                <Input disabled={isSimpleLib} value={createData.version || editData.version} onChange={(e) => handleCreateValue(e, 'version')} />
                            </Item>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            <Item label={'Descrição'}>
                                <Input.TextArea disabled={isSimpleLib} value={createData.description || editData.description} onChange={(e) => handleCreateValue(e, 'description')} />
                            </Item>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            <Item label={'Código'}>
                                <Editor
                                    language='json'
                                    value={editData?.envs?.dev?.code && JSON.stringify({
                                        extends: editData?.envs?.dev?.code?.extends,
                                        properties: editData?.envs?.dev?.code.properties,
                                        methods: editData?.envs?.dev?.code.methods
                                    }, null, 4)}
                                    theme='vs-dark'
                                    height="65vh" 
                                    onChange={(newValue) => {
                                        // if(saveButtonDisabled) {
                                        //     setSaveButtonDisabled(false)
                                        // }

                                        // setEditorCodePrd(newValue)
                                    }}
                                    options={{
                                        autoIndent: "full",
                                        readOnly: isSimpleLib
                                    }}
//                                      // extender outra classe (apenas classes criadas na lib)
//     "extends": ${JSON.stringify(editData?.envs?.dev?.code?.extends, null, 4) || '""'},
//     // propriedades da classe
//     "properties": ${JSON.stringify(editData?.envs?.dev?.code.properties, null, 4)},
//     // metódos da classe
//     "methods": 
//         ${JSON.stringify(editData?.envs?.dev?.code.methods, null, 10)}
// }` || `module.exports = {
//     // extender outra classe (apenas classes criadas na lib)
//     "extends": '',
//     // propriedades da classe
//     "properties": {},
//     // metódos da classe
//     "methods": {}
// `
                                />
                            </Item>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            <Item label={
                                <Typography.Title level={3} style={{ textAlign: 'left' }}>
                                    Readme
                                </Typography.Title>
                            }>
                                {/* <Input.TextArea disabled={isSimpleLib} value={createData.readme || editData.readme} onChange={(e) => handleCreateValue(e, 'readme')} /> */}
                                <div 
                                    style={{ 
                                        textAlign: 'left',
                                        border: '1px solid #d9d9d9',
                                        borderRadius: '6px',
                                        padding: '4px 11px',
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                    }} 
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked.parse(editData.readme ?? '')) }}
                                ></div>
                            </Item>
                        </Col>
                    </Row>
                    {
                        (userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && editData.client_id != null) && (
                            <Button style={{ float: 'right', top: 5 }} type="primary" onClick={handleCreateModal}>Salvar</Button>
                        )
                    }
                    <Button style={{ float: 'right', top: 5, marginRight: 10 }} type={'text'} onClick={() => {
                        setEditData({})
                        setOpenCreateModal(false)
                    }}>{editData.client_id == null ? 'Voltar' : 'Cancelar'}</Button>
                </Form>
            </div>
        )
    }

    const handleDestroyModalOpen = () => {
        return <DestroyConnectionModal 
            title={`Excluir conexão`} 
            open={openDestroyModal} 
            handleOpen={setOpenDestroyModal} 
            id={destroyData._id}
            name={destroyData.name} 
            mutating={mutating}
        />
    }

    const handleLogsModalOpen = () => {
        return <MigrationLogsModal 
            open={openConnectionLogModal} 
            handleOpen={setOpenConnectionLogModal} 
            connectionId={connectionIdLog}
            setConnectionId={setConnectionLogId}
        />
    }

    useEffect(() => {
        if(Object.keys(createData).length || Object.keys(editData).length) setOpenCreateModal(true)
        else setOpenCreateModal(false)
    }, [createData])

    useEffect(() => {
        if(Object.keys(editData).length) {
            setCreateData({})
            const simpleLib = (Object.keys(editData)?.length && editData?.client_id == null) && true || false
            
            setIsSimpleLib(simpleLib)
        }
    }, [editData])

    useEffect(() => {
        if(Object.keys(destroyData).length) setOpenDestroyModal(true)
        else setOpenDestroyModal(false)
    }, [destroyData])

    useEffect(() => {
        if(connectionIdLog !== null) setOpenConnectionLogModal(true)
        else setOpenConnectionLogModal(false)
    }, [connectionIdLog])

    useEffect(() => {
        const simpleLib = (Object.keys(editData)?.length && editData?.client_id == null) && true || false

        if(Object.keys(editData).length) {
            setIsSimpleLib(simpleLib)
        } else {
            setIsSimpleLib(false)
        }
    }, [openCreateModal])

    useEffect(() => {
        setTableData(libsData?.map((lib, i) => {
            return {
                key: String(i),
                ...lib
            }
        }) || [])
        setIsLoading(false)
    }, [libsIsloading])

    return (
        <>
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_DELETE) && (
                    handleDestroyModalOpen()
                )
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_LIST) && (
                    handleLogsModalOpen()
                )
            }
            <PrincipalContainer menuItemSelected='13' setCurrentPage={setCurrentPage} content={
                <div>
                    {
                        userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                            handleCreateModalOpen()
                        )
                    }
                    <div style={{ display: !openCreateModal ? 'block' : 'none' }}>
                        <Typography.Title level={2} style={{ textAlign: 'left' }}>
                            Libs 
                            <Tooltip placement="topLeft" title={``}>
                                <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                            </Tooltip>
                            {/* {userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                                <Button style={{ float: 'right', top: 5 }} type="primary" shape="round" onClick={handleCreateModal}>+</Button>
                            )} */}
                        </Typography.Title>
                        <Row>
                            <Col span={24}>
                                <Table 
                                    columns={tableColumns}
                                    isLoading={isLoading}
                                    tableData={tableData}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            } />
        </>
    )
}