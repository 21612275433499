import API from '../../services/api'
import { URLS } from '../../environment.js'

const login = async ({ email, password }) => {
    if ((!email.length && !password.length)) return []

    const { data } = await API.post(URLS.login, { email, password })

    if(data?.data?.token) {
        localStorage.setItem('@user', JSON.stringify(data?.data?.user))
        localStorage.setItem('@token', data?.data?.token)
        localStorage.setItem('@refresh_token', data?.data?.refresh_token)
    }

    return data
}

const Auth = {
    login
}

export default Auth