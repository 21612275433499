import React, { useEffect, useState } from 'react'
import { 
    Typography, 
    Row,
    Col
} from 'antd'
import useSWR from 'swr'
import { 
    Table
} from '../../components'
import {
    PrincipalContainer
} from '../../containers'
import { Transactions } from '../../modules'

export default function TransactionsPage ({ setCurrentPage }) {
    const [tableData, setTableData] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    tableColumns = [
        {
            title: 'ID',
            dataIndex: 'transaction_id',
            responsive: [ 'md' ],
        },
        {
            title: 'Valor',
            dataIndex: 'value',
            render: (_, row) =>  {
                return (
                    <Typography.Text style={{ color: row?.type == '1' ? 'green' : 'red' }}>
                        {row?.value}
                    </Typography.Text>
                )
            }
        },
        {
            title: 'Data/Hora',
            dataIndex: 'created_at',
        },
    ]

    const { data: transactionsData } = useSWR('@transactions', Transactions.getUserTransactions)

    useEffect(() => {
        setTableData(transactionsData?.map((transaction, i) => {
            const createdAt = new Date(transaction.created_at)

            return {
                key: String(i),
                transaction_id: transaction._id,
                type: transaction?.type,
                created_at: `${createdAt.getDate()}/${createdAt.getMonth() + 1 < 10 ? '0' + (createdAt.getMonth() + 1) : createdAt.getMonth() + 1}/${createdAt.getFullYear()} ${createdAt.getHours()}:${createdAt.getMinutes()}`,
                value: transaction.value.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                })
            }
        }) || [])
        setIsLoading(false)
    }, [transactionsData])

    return (
        <>
            <PrincipalContainer menuItemSelected='7' setCurrentPage={setCurrentPage} content={
                <>
                    <Typography.Title level={2} style={{ textAlign: 'left' }}>
                        Transações
                    </Typography.Title>
                    <Row>
                        <Col span={24}>
                            <Table 
                                columns={tableColumns}
                                isLoading={isLoading}
                                tableData={tableData}
                            />
                        </Col>
                    </Row>
                </>
            } />
        </>
    )
}