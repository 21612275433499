import React, { useEffect, useState } from "react"
import { 
    Modal,
    Input,
    Form,
    Tag,
    Tabs,
    Switch,
    Tooltip,
    Col,
    Row,
    Select
} from 'antd'
import {
    CheckOutlined,
    CloseOutlined,
} from '@ant-design/icons'
import { Notification } from "../.."
import { 
    Workers
} from "../../../modules"
import DevMode from "./dev-mode"
import { useSWRConfig } from "swr"

const { Item } = Form

export default function WorkerModal ({ title = '', open = false, handleOpen = () => {}, editWorker = {}, mutating = () => {} }) {
    const { cache } = useSWRConfig()

    const [worker, setWorker] = useState(null),
    [loading, setLoading] = useState(false),
    [modalIsLoading, setModalIsLoading] = useState(true),
    isEditModal = editWorker?._id ? true : false

    const connectionsData = cache.get('@connections')
    const groupsData = cache.get('@groups')
    const { data: workersData, isLoading: workersIsLoading } = cache.get('@workers') ?? { data: [] }

    const handleSave = async () => {
        setLoading(true)

        if(!worker?.name?.length) {
            setLoading(false)

            return Notification({ 
                description: `O campo Nome é obrigatório`,
                title: 'Salvar Worker',
                type: 'warning'
            })
        }

        // list dev mode
        if(worker.dev_mode.dev.code.length > 0) worker.dev_mode.dev.code = worker.dev_mode.dev.code
        if(worker.dev_mode.sdb.code.length > 0) worker.dev_mode.sdb.code = worker.dev_mode.sdb.code
        if(worker.dev_mode.hml.code.length > 0) worker.dev_mode.hml.code = worker.dev_mode.hml.code
        if(worker.dev_mode.prd.code.length > 0) worker.dev_mode.prd.code = worker.dev_mode.prd.code

        // show dev mode
        if(worker.dev_mode.dev.code.length > 0) worker.dev_mode.dev.code = worker.dev_mode.dev.code
        if(worker.dev_mode.sdb.code.length > 0) worker.dev_mode.sdb.code = worker.dev_mode.sdb.code
        if(worker.dev_mode.hml.code.length > 0) worker.dev_mode.hml.code = worker.dev_mode.hml.code
        if(worker.dev_mode.prd.code.length > 0) worker.dev_mode.prd.code = worker.dev_mode.prd.code

        // create dev mode
        if(worker.dev_mode.dev.code.length > 0) worker.dev_mode.dev.code = worker.dev_mode.dev.code
        if(worker.dev_mode.sdb.code.length > 0) worker.dev_mode.sdb.code = worker.dev_mode.sdb.code
        if(worker.dev_mode.hml.code.length > 0) worker.dev_mode.hml.code = worker.dev_mode.hml.code
        if(worker.dev_mode.prd.code.length > 0) worker.dev_mode.prd.code = worker.dev_mode.prd.code

        // update dev mode
        if(worker.dev_mode.dev.code.length > 0) worker.dev_mode.dev.code = worker.dev_mode.dev.code
        if(worker.dev_mode.sdb.code.length > 0) worker.dev_mode.sdb.code = worker.dev_mode.sdb.code
        if(worker.dev_mode.hml.code.length > 0) worker.dev_mode.hml.code = worker.dev_mode.hml.code
        if(worker.dev_mode.prd.code.length > 0) worker.dev_mode.prd.code = worker.dev_mode.prd.code

        // delete dev mode
        if(worker.dev_mode.dev.code.length > 0) worker.dev_mode.dev.code = worker.dev_mode.dev.code
        if(worker.dev_mode.sdb.code.length > 0) worker.dev_mode.sdb.code = worker.dev_mode.sdb.code
        if(worker.dev_mode.hml.code.length > 0) worker.dev_mode.hml.code = worker.dev_mode.hml.code
        if(worker.dev_mode.prd.code.length > 0) worker.dev_mode.prd.code = worker.dev_mode.prd.code
        // setLoading(false)
        // return console.log(worker.request) 
        
        setWorker({ ...worker })

        let hasError = false,
            response = null

        if(isEditModal) {
            response = await Workers.updateClientWorker({ worker, id: editWorker?._id })

            if(response?.data?.has_prs) {
                setLoading(false)
                handleOpen(false)

                return handleNotificationPR()
            }

            hasError = response == true || false
        } else {
            response = await Workers.createClientWorker({ worker })

            hasError = response == true || false
        }

        setLoading(false)

        if(hasError) return 

        handleOpen(false)
        handleNotificationSuccess()
        await mutating()
    }

    const handleNotificationPR = () => {
        Notification({ 
            description: `Não foi possível atualizar o Worker, há PRs em aberto, verifique e tente novamente`,
            title: 'Worker',
            type: 'error'
        })
    }

    const handleNotificationSuccess = () => {
        const modalType = isEditModal ? 'editado' : 'criado'
        
        Notification({ 
            description: `Worker ${modalType} com sucesso`,
            title: 'Worker'
        })
    }

    const handleValue = (e, key, type = null, isDelete = false) => {
        const splitedKeys = key.split('.'),
            hasMoreThanOneKey = splitedKeys.length > 1

        const value = e?.target?.value

        if(key.match(/dev_mode/)?.length > 0) {
            const keySplit = key.split('.')
            worker.dev_mode[keySplit[1]].code = `${e}`
        } else if(hasMoreThanOneKey) {
            let tmpValue = null

            splitedKeys.forEach((key, i) => {
                if(!i) {
                    tmpValue = worker[key]
                } else if (i !== (splitedKeys.length - 1)) {
                    tmpValue = tmpValue[key]
                }
            })

            if(type && !isDelete) {
                const r = new RegExp(`array.string.\*`, 'g')

                if(type.match(r)?.length) {
                    const index = parseInt(type.split('.')[2])
                    let hasIndex = typeof tmpValue[splitedKeys[splitedKeys.length - 1]][index] == 'string'

                    if(!hasIndex) tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                    else {
                        let newValue = hasIndex ? tmpValue[splitedKeys[splitedKeys.length - 1]][index] : ''
                        
                        newValue = !value ? '': value
    
                        tmpValue[splitedKeys[splitedKeys.length - 1]][index] = newValue
                    }
                } else if(!value || isDelete) {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = []
                } else {
                    tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                }
            } else {
                tmpValue[splitedKeys[splitedKeys.length - 1]] = value
            }
        } else {
            worker[key] = value
        }

        setWorker({ ...worker })
    }

    const handleEnableWorker = () => {
        const newValue = !worker.status ? true : false
        handleValue({ target: { value: newValue } }, 'status')
    }

    const handleEnableShowErrors = () => {
        const newValue = !worker.errors_log ? true : false
        handleValue({ target: { value: newValue } }, 'errors_log')
    }

    useEffect(() => {
        if(Object.keys(editWorker)?.length) {
            setWorker({
                'name': editWorker?.name,
                'worker_description': editWorker?.worker_description,
                'connection_id': editWorker?.connection_id,
                'group_id': editWorker?.group_id,
                'worker_id': editWorker?.worker_id,
                'status': Boolean(editWorker?.status) || false,
                'pr_description': '',
                'dev_mode': {
                    'dev': {
                        'code': editWorker?.dev_mode?.dev?.code || '',
                    },
                    'sdb': {
                        'code': editWorker?.dev_mode?.sdb?.code || '',
                    },
                    'hml': {
                        'code': editWorker?.dev_mode?.hml?.code || '',
                    },
                    'prd': {
                        'code': editWorker?.dev_mode?.prd?.code || '',
                    }
                }
            })
        } else {
            setWorker({
                'name': '',
                'worker_description': '',
                'connection_id': null,
                'group_id': null,
                'worker_id': null,
                'status': true,
                'pr_description': '',
                'dev_mode': {
                    'dev': {
                        'code': '',
                    },
                    'sdb': {
                        'code': '',
                    },
                    'hml': {
                        'code': '',
                    },
                    'prd': {
                        'code': '',
                    }
                }
            })
        }

        setModalIsLoading(false)
    }, [editWorker])

    return (
        <Modal
            title={title}
            centered
            open={open}
            onCancel={async () => { 
                handleOpen(false) 
            }}
            width={1000}
            onOk={async () => handleSave()}
            okText="Salvar"
            cancelText="Cancelar"
            cancelButtonProps={{ type: 'text' }}
            // okButtonProps={{ loading: loading }}
        >
            {!modalIsLoading && (
                <Row 
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col span={24}>

                        <Form 
                            layout={'vertical'}
                            initialValues={{
                                ...worker
                            }}
                        >
                            <Row 
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col className="gutter-row" span={9}>
                                    <Item label="Nome">
                                        <Input placeholder="Nome do worker" onChange={(e) => handleValue(e, 'name')} value={worker?.name}  />
                                    </Item>
                                </Col>
                                <Col className="gutter-row" span={9}>
                                    <Item label="Grupo">
                                        <Select
                                            style={{
                                                width: '100%',
                                            }}
                                            options={[{ label: 'Nenhum Grupo', value: null }].concat(groupsData?.data?.map(group => {
                                                return {
                                                    label: group.name,
                                                    value: group._id
                                                }
                                            })) || []} 
                                            onChange={(e) => handleValue({ target: { value: e }}, 'group_id')}
                                            onInputKeyDown={(e) => e.preventDefault()}
                                            value={worker?.group_id}
                                        />
                                    </Item>
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    <Item label="Mostrar erros">
                                        <Tooltip placement="topLeft" title="Mostrar erros de execução do Worker">
                                            <Switch 
                                                onChange={handleEnableShowErrors}
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                checked={worker?.errors_log}
                                            />
                                        </Tooltip>
                                    </Item>
                                </Col>
                            </Row>

                            <Row 
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col className="gutter-row" span={18}>
                                    <Item label="Descrição">
                                        <Input.TextArea placeholder="Descrição da API" onChange={(e) => handleValue(e, 'worker_description')} value={worker?.worker_description}  />
                                    </Item>
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    <Item label="Conexão">
                                        <Select
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Selecionar conexão"
                                            loading={connectionsData?.data?.length > 0 ? false : true}
                                            options={connectionsData?.data?.length > 0 && connectionsData?.data?.map(conn => {
                                                return {
                                                    label: conn.name,
                                                    value: conn._id
                                                }
                                            }) || []} 
                                            onChange={(e) => handleValue({ target: { value: e }}, 'connection_id')}
                                            onInputKeyDown={(e) => e.preventDefault()}
                                            value={worker?.connection_id}
                                        />
                                    </Item>
                                </Col>
                            </Row>

                            <Row 
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col className="gutter-row" span={6}>
                                    {
                                        isEditModal && (
                                            <Item label="Status">
                                                <Tooltip placement="topLeft" title="worker ativo/desativado">
                                                    <Switch 
                                                        onChange={handleEnableWorker}
                                                        checkedChildren={<CheckOutlined />}
                                                        unCheckedChildren={<CloseOutlined />}
                                                        checked={worker?.status}
                                                    />
                                                </Tooltip>
                                            </Item>
                                        )
                                    }
                                </Col>
                            </Row>

                            <Row 
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col span={24}>
                                    <DevMode
                                        handleValue={handleValue}
                                        values={editWorker}
                                        isEdit={isEditModal}
                                        workerId={worker?.id || null}
                                    />
                                </Col>
                            </Row>

                            <Row 
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col span={24}>
                                    <Item label="Executar após">
                                        <Select
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Selecionar worker"
                                            loading={workersData?.length > 0 ? false : true}
                                            options={workersData?.length > 0 && workersData?.filter(worker => worker?._id != editWorker?._id)?.map(worker => {
                                                return {
                                                    label: worker.name,
                                                    value: worker._id
                                                }
                                            }) || []} 
                                            onChange={(e) => handleValue({ target: { value: e }}, 'worker_id')}
                                            onInputKeyDown={(e) => e.preventDefault()}
                                            value={worker?.worker_id}
                                        />
                                    </Item>
                                </Col>
                            </Row>
            
                        </Form>

                    </Col>
                </Row>
            )}
        </Modal>
    )
} 