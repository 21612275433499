import React, { useState, useEffect } from "react"
import { 
    Tag,
    Button,
    Divider,
    Switch,
    Tooltip,
    Form,
    Tabs,
    Input,
    Select,
    Row,
    Col,
    Typography
} from 'antd'
import {
    CheckOutlined,
    CloseOutlined,
    InfoCircleOutlined,
    ThunderboltOutlined,
    CoffeeOutlined
} from '@ant-design/icons'
import Editor from '@monaco-editor/react'
import { Notification } from "../.."

const { Item } = Form

export default function TabPost ({ handleValue = () => {}, values = {}, isEdit = false, appId = null, endpointName = null, endpointName2 = null, workers = [] }) {
    const [endpointDisabled, setEndpointDisabled] = useState(values?.request?.status || false),
    [saveButtonDisabled, setSaveButtonDisabled] = useState(true),
    [saveSchemaButtonDisabled, setSaveSchemaButtonDisabled] = useState(true),
    [editorCodePrd, setEditorCodePrd] = useState(''),
    [editorCodeHml, setEditorCodeHml] = useState(''),
    [editorCodeSdb, setEditorCodeSdb] = useState(''),
    [editorCodeDev, setEditorCodeDev] = useState(''),
    [editorCodeRequest, setEditorCodeRequest] = useState('')

    const handleEnableEndpoint = () => {
        const newValue = !endpointDisabled ? true : false
        setEndpointDisabled(newValue)
        handleValue({ target: { value: Number(newValue) } }, 'request.create.status')
    }

    let hasNotified = false

    const draggerProps = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        accept: 'application/json',
        showUploadList: false,
        action: "#",
        onChange(info) {
            hasNotified = false

            const fr = new FileReader()

            fr.addEventListener("load", e => {
                const json = JSON.parse(fr.result)

                handleValue({ target: { value: json } }, 'request.create.schema')
                if(hasNotified) return 

                Notification({
                    description: `Schema configurado com sucesso`,
                    title: 'Upload JSON'
                })

                hasNotified = true
            })

            fr.readAsText(info.file.originFileObj)
        }
    }

    const handleSaveSchema = () => {
        handleValue({ target: { value: editorCodeRequest } }, 'request.create.schema')

        setSaveSchemaButtonDisabled(true)
    }

    const handleSaveCode = () => {
        const codePrd = values?.request?.dev_mode?.prd.code
        if(editorCodePrd != codePrd) handleValue(editorCodePrd, 'request.create.dev_mode.prd.code')

        const codeHml = values?.request?.dev_mode?.hml.code
        if(editorCodeHml != codeHml) handleValue(editorCodeHml, 'request.create.dev_mode.hml.code')

        const codeSdb = values?.request?.dev_mode?.sdb.code
        if(editorCodeSdb != codeSdb) handleValue(editorCodeSdb, 'request.create.dev_mode.sdb.code')

        const codeDev = values?.request?.dev_mode?.dev.code
        if(editorCodeDev != codeDev) handleValue(editorCodeDev, 'request.create.dev_mode.dev.code')

        setSaveButtonDisabled(true)
    }

    useEffect(() => {
        setEditorCodePrd('')
        setEditorCodeHml('')
        setEditorCodeSdb('')
        setEditorCodeDev('')
    }, [appId])

    useEffect(() => {
        setSaveButtonDisabled(true)
        setSaveSchemaButtonDisabled(true)
        setEndpointDisabled(Boolean(values?.request?.status))
        setEditorCodeRequest(values?.request?.schema)

        if((values?.request?.dev_mode?.prd.code[0])) {
            const codePrd = values?.request?.dev_mode?.prd.code
            if((editorCodePrd.length != codePrd.length)) setEditorCodePrd(codePrd)
        }

        if((values?.request?.dev_mode?.hml.code[0])) {
            const codeHml = values?.request?.dev_mode?.hml.code
            if((editorCodeHml.length != codeHml.length)) setEditorCodeHml(codeHml)
        }

        if((values?.request?.dev_mode?.sdb.code[0])) {
            const codeSdb = values?.request?.dev_mode?.sdb.code
            if((editorCodeSdb.length != codeSdb.length)) setEditorCodeSdb(codeSdb)
        }

        if(values?.request?.dev_mode?.dev.code[0]) {
            const codeDev = values?.request?.dev_mode?.dev.code
            if((editorCodeDev.length != codeDev.length)) setEditorCodeDev(codeDev)
        } 

        console.log(values)
    }, [values, appId])

    const tabsEnv = [
        <>
            <h3 style={{ textAlign: 'left' }}>
                Produção
            </h3>
            <Editor
                language='javascript'
                value={editorCodePrd || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodePrd(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Homologação
            </h3>
            <Editor
                language='javascript'
                value={editorCodeHml || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeHml(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Sandbox
            </h3>
            <Editor
                language='javascript'
                value={editorCodeSdb || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeSdb(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Desenvolvimento
            </h3>
            <Editor
                language='javascript'
                value={editorCodeDev || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeDev(newValue)
                }}
                options={{
                    autoIndent: "full"
                }}
            />
        </>
    ]

    const tabs = [
        <>
            <Divider orientation="left">
                Requisição
                <Tooltip placement="topLeft" title="Configurações da requisição">
                    <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                </Tooltip>    
            </Divider>
            
            <div id={'request-params'}>
                <Item label={(<>
                    {'Schema'}
                    <Tooltip placement="topLeft" title={(<>{`Você pode definir um schema de requisição para fazer a validação dos campos e tipo de dados enviados à API,
                        consulte a documentação do `}<a href="https://ajv.js.org/json-schema.html" target={'_BLANK'}>AJV</a>{` para mais detalhes`}</>)}>
                        <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                    </Tooltip>
                    </>)}
                >
                    <Editor
                        language='json'
                        value={editorCodeRequest || ''}
                        theme='vs-dark'
                        height="50vh" 
                        onChange={(newValue) => {
                            if(saveButtonDisabled) {
                                setSaveSchemaButtonDisabled(false)
                            }

                            setEditorCodeRequest(newValue)
                        }}
                        options={{
                            autoIndent: "full"
                        }}
                    />
                    <Button style={{ marginTop: 10, float: 'right' }} disabled={saveSchemaButtonDisabled} type="default" shape="round" onClick={handleSaveSchema}>Salvar Schema</Button>
                    {/* <Dragger 
                        { ...draggerProps }
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Clique ou arraste e solte o arquivo .json</p>
                    </Dragger> */}
                    <Row
                        gutter={{
                            xs: 8,
                            sm: 16,
                            md: 24,
                            lg: 32,
                        }}
                    >
                        <Col className="gutter-row" span={24}>
                            <Item label={(<>
                                {'Worker'}
                                <Tooltip placement="topLeft" title={`Execute um worker após finalizar o processamento do endpoint`}>
                                    <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                                </Tooltip>
                            </>)}>
                                <Select
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Selecionar Worker"
                                    loading={workers?.data?.length > 0 ? false : true}
                                    options={workers?.data?.length > 0 && workers?.data?.map(worker => {
                                        return {
                                            label: worker.name,
                                            value: worker._id
                                        }
                                    }) || []} 
                                    onChange={(e) => handleValue({ target: { value: e }}, 'request.create.worker_id')}
                                    onInputKeyDown={(e) => e.preventDefault()}
                                    value={values?.request?.worker_id}
                                />
                            </Item>
                        </Col>
                    </Row>
                </Item>
            </div>
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Dev Mode
            </h3>
            <h5 style={{ textAlign: 'left' }}>
                Faça o clone do nosso <a href="https://github.com/codetec-repos/simplerest-dev-boilerplate">boilerplate</a> para auxiliar no desenvolvimento <br></br>
                Quando houver código no "Dev Mode" o "Simple Mode" é ignorado, menos a validação de input.
                O código deverá sempre terminar com um "return Object" ou "return Array", para conter um valor na resposta<br></br>
                Após feita a modificação, clique em "salvar código"  e após salvar, será aberto um PR para aprovação ou não do código<br></br>
                * no Sandbox use apenas o ambiente de "desenvolvimento" para teste
            </h5>
            <Input placeholder="Digite a descrição da Pull Request" value={values?.description} onChange={(e) => handleValue(e, 'request.create.description')} />
            <Tabs
                defaultActiveKey="2"
                items={['Produção', 'Homologação', 'Sandbox', 'Desenvolvimento'].map((tabName, i) => {
                    return {
                        label: (
                        <span>
                            {tabName}
                        </span>
                        ),
                        key: i + 1,
                        children: tabsEnv[i],
                    }
                })}
            />
            <Typography.Paragraph style={{ marginTop: 10, cursor: 'pointer' }}>Default vars: 
                <Tooltip placement="topLeft" title={`
                    Variável process do NodeJS
                `}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>process</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={`
                    Variável require do NodeJS
                `}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>require</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={`
                    Variável com instancia do Knex.js
                `}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>db</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={`
                    const {
                        body,
                        session,
                        files: [],
                        simpleApiCallService: {
                            call: async (apiId, method : 'list' | 'get' | 'post' | 'put' | 'delete', data = {}, params = {}) => {}
                        }
                    } = simplerest
                `}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>simplerest</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={`
                    const {
                        S3Service,
                        SimplePayments,
                        PDFGenerator,
                        CSVGenerator,
                        DateFormat,
                        SimpleNotifications
                    } = simplelib
                `}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>simplelib</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={`
                    const {
                        email: {
                            config: {
                                host: "",
                                port: "",
                                secure: false
                            },
                            auth: { 
                                user: "",
                                pass: 
                            },
                            emailParams: {
                                from: 
                            }
                        },
                        slack: {
                            auth: {
                                oauthToken: 
                            }
                        },
                        zenvia: {
                            auth: {
                                token: 
                            }
                        },
                        stripe: {
                            auth: {
                                secretKey: ""
                            }
                        },
                        mercadoPago: {
                            auth: {
                                accessToken: "",
                                webhook: 
                            }
                        },
                        aws: {
                            auth: {
                                region: "",
                                accessKey: "",
                                secretKey: ""
                            }
                        }
                    } = simplekeys
                `}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>simplekeys</Typography.Text>
                </Tooltip>,
                <Tooltip placement="topLeft" title={`
                    const {
                        list: async (pattern = '*') => {},
                        get: async (key) => {},
                        set: async (key, value, expires) => {},
                        del: async (key) => {}
                    } = cache
                `}>
                    <Typography.Text style={{ marginLeft: 10 }} strong>cache</Typography.Text>
                </Tooltip>
            </Typography.Paragraph>
            <Button style={{ marginTop: 10, float: 'right' }} disabled={saveButtonDisabled} type="default" shape="round" onClick={handleSaveCode}>Salvar código</Button>
        </>
    ]

    return (
        <div>
            <h3 style={{ textAlign: 'left' }}>
                <Tag color="processing">POST</Tag> {isEdit ? (endpointName2?.length > 0 ? `/v1/${appId}${endpointName?.length > 0 ? `|${endpointName}` : ''}/${endpointName2}` : `/v1/${appId}${endpointName?.length > 0 ? `|${endpointName}` : ''}`) : `/v1/{app_id}|{endpoint_name}/?{endpoint_name2}`}
                <Tooltip placement="topLeft" title="Endpoint ativo/desativado">
                    <Switch 
                        onChange={handleEnableEndpoint}
                        style={{ float: 'right' }}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={endpointDisabled}
                    />
                </Tooltip>
            </h3>
            
            <Tabs
                defaultActiveKey="2"
                items={[ThunderboltOutlined, CoffeeOutlined].map((Icon, i) => {
                    const tabName = i == 0 ? 'Simple Mode' : 'Dev Mode' 
                    return {
                        label: (
                        <span>
                            <Icon />
                            {tabName}
                        </span>
                        ),
                        key: i + 1,
                        children: tabs[i],
                    }
                })}
            />
        </div>
    )
} 