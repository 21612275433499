import React from "react"
import { notification } from 'antd'

export default function VideoModal ({ description, type = 'success', placement = 'bottomLeft', title }) {
    const getNotification = () => {
        return notification[type]({
            message: title,
            description,
            placement
        })
    }
    
    return getNotification()
} 