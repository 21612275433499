import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
  verifyPermission, 
  permissionsStatus 
} from '../../helpers/permissions'

const getPullRequests = async () => {
  if(!verifyPermission('pull_request', permissionsStatus.list)) return []

  const { data: response } = await API.get(URLS.pull_request.list(500, 0))

  return response
}

const approvePullRequest = async ({ id }) => {
  if(!verifyPermission('pull_request', permissionsStatus.update)) return []

  const { data: response } = await API.post(URLS.pull_request.update(id))

  return response
}

const deaprovePullRequest = async ({ id }) => {
  if(!verifyPermission('pull_request', permissionsStatus.delete)) return []

  const { data: response } = await API.delete(URLS.pull_request.delete(id))

  return response
}

const PullRequests = {
  getPullRequests,
  approvePullRequest,
  deaprovePullRequest
}

export default PullRequests