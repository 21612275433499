const permissionsStatus = {
    delete: 0,
    create: 1, 
    update: 2, 
    list: 3 
}

const verifyPermission = (type, value) => {
    const permissions = JSON.parse(localStorage.getItem('@user') || '{}')?.permissions,
        hasPermission = permissions?.[type]?.includes(value)

    return hasPermission
}

export {
    verifyPermission,
    permissionsStatus
}