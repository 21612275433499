import React, { useEffect, useState } from 'react'
import { 
    Typography, 
    Space, 
    Row,
    Col,
    Select,
    QRCode,
    Popover
} from 'antd'
import useSWR, { useSWRConfig } from 'swr'
import { 
    Table
} from '../../components'
import {
    PrincipalContainer
} from '../../containers'
import { Billing } from '../../modules'
import { 
    DownloadOutlined,
    QrcodeOutlined
} from '@ant-design/icons'

const { Link } = Typography

export default function BillingPage ({ setCurrentPage }) {
    const [tableData, setTableData] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    [yearSearch, setYearToSearch] = useState(String(new Date().getFullYear())),
    [yearsToSearch, setYearsToSearch] = useState([]),
    tableColumns = [
        {
            title: 'Mês',
            dataIndex: 'month',
            key: 'month',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => {
                const months = {
                    1: 'Janeiro',
                    2: 'Fevereiro',
                    3: 'Março',
                    4: 'Abril',
                    5: 'Maio',
                    6: 'Junho',
                    7: 'Julho',
                    8: 'Agosto',
                    9: 'Setembro',
                    10: 'Outubro',
                    11: 'Novembro',
                    12: 'Dezembro'
                }

                return (
                    <>
                        {months[row.month]}
                    </>
                )
            }
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     key: 'status',
        //     responsive: [ 'xs', 'sm', 'md', 'lg' ],
        //     render: (_, row) => (
        //         <>
        //             {row.status ? 'Pago' : 'Em aberto' }
        //         </>
        //     )
        // },
        {
            title: 'Valor',
            dataIndex: 'value',
            key: 'value',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                <>
                    {row.value.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })}
                </>
            )
        },
        {
            title: 'Ações',
            dataIndex: 'actions',
            key: '_id',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                <Space size="middle">
                    <Link target={'blank'} href={row.link}>
                        <DownloadOutlined  style={{ fontSize: '16px' }} />
                    </Link>
                    {/* {(!row.status && row?.qrcode?.length) && (
                        <Popover
                            overlayInnerStyle={{
                                padding: 0
                            }}
                            content={<img style={{ width: 200 }} src={`data:image/png;base64,${row.qrcode}`} />}
                        >
                            <QrcodeOutlined style={{ fontSize: '16px', color: '#d9534f' }} />
                        </Popover>
                    )} */}
                </Space>
            )
        }
    ]

    const { mutate } = useSWRConfig()

    const { data: billingsData } = useSWR('@billings')

    const filterByYear = (year) => {
        setYearToSearch(year)
        setIsLoading(true)
    }

    useEffect(() => {
        const year = parseInt((new Date()).getFullYear())

        const years = [{ label: String(year), value: String(year) }, { label: String(year - 1), value: String(year - 1) }]
        
        setYearsToSearch([ ...years ])
    }, [])

    useEffect(() => {
        setTableData(billingsData?.data?.map((billing, i) => {
            return {
                key: String(i),
                ...billing
            }
        }) || [])

        setIsLoading(false)
    }, [billingsData])

    useEffect(() => {
        const mutating = async () => {
            await mutate('@billings', () => Billing.getClientBillings({ year: yearSearch }))
            setIsLoading(false)
        }

        mutating()
    }, [yearSearch])

    return (
        <>
            <PrincipalContainer menuItemSelected='4' setCurrentPage={setCurrentPage} content={
                <>
                    <Typography.Title level={2} style={{ textAlign: 'left' }}>
                        Relatórios Mensais
                        <Select
                            defaultValue={yearSearch}
                            style={{
                                width: 240,
                                marginLeft: 15
                            }}
                            onSelect={(e) => filterByYear(e)}
                            options={[
                                {
                                    label: 'Escolha um ano para pesquisar...',
                                    value: ''
                                },
                                ...yearsToSearch
                            ]}
                        />
                    </Typography.Title>
                    <Row>
                        <Col style={{ overflow: 'scroll' }} span={24}>
                            <Table 
                                columns={tableColumns}
                                isLoading={isLoading}
                                tableData={tableData}
                            />
                        </Col>
                    </Row>
                </>
            } />
        </>
    )
}