const localeStrings = {
    "pt-BR": {
        'max apis for this plan is reached, upgrade your plan for more apis': 'o máximo de apis foi atingido para o plano atual, faça um upgrade no plano para mais apis',
        'max users for this plan is reached, upgrade your plan for more users': 'o máximo de usuários foi atingido para o plano atual, faça um upgrade no plano para mais usuários',
        'max connections for this plan is reached, upgrade your plan for more connections': 'o máximo de conexões foi atingido para o plano atual, faça um upgrade no plano para mais conexões',
        'the api has the same name as other api, please rename this api to another name and try again': 'a api tem o mesmo nome de outra api, altere o nome da api atual para outro nome e tente novamente',
        'the api has the same endpoint as other api, please change the endpoint name of this api to another endpoint name and try again': 'a api tem o mesmo endpoint(s) de outra api, altere o(s) endpoint(s) e tente novamente',
        'the api has pending PRs to approve, please check them and try again': 'a api tem PRs pendentes para aprovar, por favor, verifique e tente novamente',
        'interval server error, contact the support for more details': 'erro de servidor interno, contate o suporte para mais detalhes',
        'update password': 'atualizar senha',
        'current password is wrong': 'a senha atual está incorreta',
        'wrong pass or email': 'email ou senha incorretos',
        'default error message': 'houve um erro ao tentar realizar a ação',
        'error': 'erro',
        'has user with this email in the database': 'existe um usuário com esse email cadastrado em nossa base de dados'
    },
    "en-US": {
        'max apis for this plan is reached, upgrade your plan for more apis.': 'max apis for this plan is reached, upgrade your plan for more apis',
        'max users for this plan is reached, upgrade your plan for more users': 'max users for this plan is reached, upgrade your plan for more users',
        'max connections for this plan is reached, upgrade your plan for more connections': 'max connections for this plan is reached, upgrade your plan for more connections',
        'the api has the same name as other api, please rename this api to another name and try again': 'the api has the same name as other api, please rename this api to another name and try again',
        'the api has the same endpoint as other api, please change the endpoint name of this api to another endpoint name and try again': 'the api has the same endpoint as other api, please change the endpoint name of this api to another endpoint name and try again',
        'the api has pending PRs to approve, please check them and try again': 'the api has pending PRs to approve, please check them and try again',
        'interval server error, contact the support for more details': 'interval server error, contact the support for more details',
        'update password': 'update password',
        'current password is wrong': 'current password is wrong',
        'wrong pass or email': 'wrong pass or email',
        'default error message': 'have an error when try to realize the action',
        'error': 'error',
        'has user with this email in the database': 'has user with this email in the database'
    }
}

const Locale = (text, uppercase = false) => {
    const language = navigator.language || 'pt-BR',
        localeString = localeStrings[language] && localeStrings[language][text] || localeStrings['en-US'][text]

    return uppercase && localeString?.charAt(0)?.toUpperCase() + localeString?.slice(1) || localeString
}

export default Locale