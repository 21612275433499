import { URLS } from '../../environment.js'
import axios from 'axios'

const api = axios.create({
    baseURL: (window.location.href.match(/sandbox/)?.length || window.location.href.match(/localhost/)?.length) ? URLS.api.sandbox_update : URLS.api.production_update
})

const getUpdates = async () => {
    try {
        const token = localStorage.getItem("@token")
    
        const { data } = await api.get(URLS.updates.list(null), {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
    
        return data?.data
    } catch (error) {
        console.log(error)        
    }
}

const Update = {
    getUpdates
}

export default Update