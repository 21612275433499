import axios from 'axios'
import cache from './cache'
import AsyncLock from 'async-lock'
import { URLS } from '../environment.js'
import { Notification } from '../components'
import Translator from '../helpers/translator'

const lock = new AsyncLock()
const UNAUTHORIZED = 401
const FORBIDDEN = 403

const api = axios.create({
    baseURL: (window.location.href.match(/sandbox/) || window.location.href.match(/localhost/)) ? URLS.api.sandbox : URLS.api.production,
})

const refreshToken = async ({ currentToken }) => {
    await lock.acquire('refreshToken', async (done) => {
        try {
            const bearerToken = localStorage.getItem("@token")

            if(!bearerToken?.length) return done()

            const res = await api.put(URLS.refresh, { refresh_token: currentToken }, {
                headers: {
                    Authorization: 'Bearer ' + bearerToken
                }
            })

            if(res?.response?.status === UNAUTHORIZED) {
                localStorage.removeItem('@token')
                localStorage.removeItem('@refresh_token')
                localStorage.removeItem('@user')
                localStorage.removeItem('@token_expires')
                
                return window.dispatchEvent(new Event("logout"))
            }

            const { data } = res.data
        
            localStorage.setItem("@token", data.token)
            localStorage.setItem("@token_expires", (new Date((new Date()).getTime() + (9.5 * 60000))).getTime())
            localStorage.setItem("@refresh_token", data.refresh_token)

            cache.set('refreshTokenRequest', false)

            done()
        } catch (err) {
            cache.set('refreshTokenRequest', false)

            if(err?.response?.status === UNAUTHORIZED) {
                localStorage.removeItem('@token')
                localStorage.removeItem('@refresh_token')
                localStorage.removeItem('@user')
                localStorage.removeItem('@token_expires')
                
                return window.dispatchEvent(new Event("logout"))
            }
        
            done()
        }
    });
}

const configureApisWithToken = axiosInstance => {
    axiosInstance.interceptors.request.use(
        async config => {
            try {
                const token = localStorage.getItem("@token")

                if (token?.length) config.headers.Authorization = 'Bearer ' + token
            } catch (error) {
                console.log("configure", error)
            }
            
            return config
        },
        err => Promise.reject(err)
    )
}

api.interceptors.response.use(
    response => response,
    async error => {
        const status = error?.response?.status

        if (status === UNAUTHORIZED || status === FORBIDDEN) {
            if((error.config.url !== '/v1/auth' && status === UNAUTHORIZED)) {
                const currentToken = localStorage.getItem("@refresh_token")
                return Promise.resolve(await refreshToken({ currentToken }))
            } else if ((error.config.url === '/v1/auth' && error.config.method === 'put' && status === UNAUTHORIZED)) {
                localStorage.removeItem('@token')
                localStorage.removeItem('@refresh_token')
                localStorage.removeItem('@user')
                localStorage.removeItem('@token_expires')
                
                return Promise.resolve(window.dispatchEvent(new Event("logout")))
            } else if ((error.config.url === '/v1/auth' && error.config.method === 'post' && status === UNAUTHORIZED)) {
                Notification({ 
                    description: Translator('wrong pass or email', true),
                    type: 'warning',
                    title: 'Login'
                }) 

                return Promise.resolve(error)
            }
        } else if ((error.config.url === '/v1/client' && error.config.method === 'put')) {
            Notification({ 
                description: Translator('current password is wrong', true),
                title: Translator('update password', true),
                type: 'warning'
            }) 

            return Promise.resolve(error)
        }

        const msg = Translator(error?.response?.data?.data?.msg || 'default error message', true)

        Notification({
            description: msg,
            title: Translator('error', true),
            type: 'warning'
        })

        error.isError = true

        return Promise.resolve(error)
    }
)

configureApisWithToken(api)

export default api