import React, { useEffect, useState } from "react"
import { 
    Modal,
    Input,
    Form,
    Row,
    Col,
    Select
} from 'antd'
import { Notification } from "../.."
import { User, UserPolicies } from "../../../modules"
import useSWR from "swr"

const { Item } = Form

export default function UserModal ({ title = '', open = false, handleOpen = () => {}, userData = {}, mutating = () => {} }) {
    const [user, setUser] = useState({
        name: userData?.name || '',
        email: userData?.email || '',
        password: '',
        // profile_pic: null,
        role: userData?.role || 0,
        policy_id: userData.policy_id || null
    }),
    [loading, setLoading] = useState(false),
    [userPolicies, setUserPolicies] = useState([]),
    isEditModal = Object.keys(userData)?.length > 1 ? true : false

    const handleSave = async () => {
        setLoading(true)

        let isOK = true,
            errorsBag = []
        
        Object.keys(user).forEach(key => {
            const value = user[key]

            switch (key) {
                case 'name':
                    isOK = (value?.length >= 3 && value?.length <= 20)
                    if(!isOK) errorsBag.push(<p>O campo Nome deve conter entre 3 e 20 caracteres e é obrigatório!</p>)
                    break
                case 'email':
                    if(value.match(/@/)) {
                        if(value.match(/.com/)) {
                            isOK = true
                        }
                    } else {
                        isOK = false
                    }
                    if(!isOK) errorsBag.push(<p>O campo Email deve ser um endereço de e-mail válido e é obrigatório!</p>)
                    break
                case 'password':
                    if(!isEditModal) {
                        isOK = value?.length >= 8
                        if(!isOK) errorsBag.push(<p>O campo Senha deve conter entre 8 ou mais caracteres e é obrigatório!</p>)
                    }
                    break
                case 'role':
                    isOK = value == 1 || value == 0
                    if(!isOK) errorsBag.push(<p>O campo Tipo de Usuário é obrigatório!</p>)
                    break
                case 'policy_id':
                    if(user.role == 0) {
                        isOK = value?.length >= 1
                        if(!isOK) errorsBag.push(<p>O campo Grupo de Permissão é obrigatório!</p>)
                    }
                    break
            }
        })

        if(!isOK) {
            setLoading(false) 

            return Notification({
                title: 'Campos obrigatórios',
                type: 'warning',
                description: errorsBag
            })
        }

        let hasError = false

        if(isEditModal) {
            const response = await User.updateUser({ user, id: user?._id })

            hasError = response == true || false
        } else {
            const response = await User.createUser({ user })

            hasError = response == true || false
        }

        setLoading(false)
        
        if(hasError) return

        handleOpen(false)
        handleNotificationSuccess()
        await mutating()
    }

    const handleNotificationSuccess = () => {
        const modalType = isEditModal ? 'editado' : 'criado'
        
        Notification({ 
            description: `Usuário ${modalType} com sucesso`,
            title: 'Usuários'
        })
    }

    const handleValue = (e, key, type = null, isDelete = false) => {
        const splitedKeys = key.split('.'),
            hasMoreThanOneKey = splitedKeys.length > 1

        const value = e?.target?.value

        if(hasMoreThanOneKey) {
            let tmpValue = null

            splitedKeys.forEach((key, i) => {
                if(!i) {
                    tmpValue = user[key]
                } else if (i !== (splitedKeys.length - 1)) {
                    tmpValue = tmpValue[key]
                }
            })

            if(type && !isDelete) {
                if(isDelete) {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = []
                } else {
                    tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                }
            } else {
                if(type == 'array') {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = tmpValue[splitedKeys[splitedKeys.length - 1]].filter(v => {
                        return v != value
                    })
                } else {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = value
                }
            }
        } else {
            user[key] = value
        }

        setUser({ ...user })
    }

    const { data: userPoliciesData } = useSWR('@usersPolicies', UserPolicies.getUsersPolicies)

    useEffect(() => {
        if(Object.keys(userData)?.length) setUser({ ...userData })
        else {
            setUser({
                name: '',
                email: '',
                password: '',
                // profile_pic: null,
                role: 0,
                policy_id: null
            })
        }
    }, [userData])

    useEffect(() => {
        setUserPolicies(userPoliciesData?.data?.map(policy => {
            return {
                label: policy?.name,
                value: policy?._id
            }
        }))
    }, [userPoliciesData])

    return (
        <Modal
            title={title}
            centered
            open={open}
            onCancel={async () => {
                handleOpen(false)
            }}
            width={1000}
            onOk={async () => await handleSave()}
            okText="Salvar"
            cancelText="Cancelar"
            cancelButtonProps={{ type: 'text' }}
            okButtonProps={{ loading: loading }}
        >
            <Row 
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}
            >
                <Col span={24}>
                    <Form 
                        layout={'vertical'}
                        initialValues={{
                            ...user
                        }}
                    >
                        <Row 
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                        >

                            <Col className="gutter-row" span={6}>
                                <Item label="Nome">
                                    <Input placeholder="Nome" onChange={(e) => handleValue(e, 'name')} value={user?.name} />
                                </Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                                <Item label="Email">
                                    <Input placeholder="Email" onChange={(e) => handleValue(e, 'email')} value={user?.email} />
                                </Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                                <Item label="Senha">
                                    <Input.Password placeholder="Senha" onChange={(e) => handleValue(e, 'password')} value={user?.password} />
                                </Item>
                            </Col>

                            {!user?.role && (
                                <Col className="gutter-row" span={6}>
                                    <Item label="Grupo de Permissão">
                                        <Select
                                            style={{
                                                width: '100%',
                                            }}
                                            options={userPolicies} 
                                            onChange={(e) => handleValue({ target: { value: e }}, 'policy_id')}
                                            onInputKeyDown={(e) => e.preventDefault()}
                                            value={user?.policy_id}
                                        />
                                    </Item>
                                </Col>
                            ) || (
                                <Col className="gutter-row" span={6}>
                                    <Item label="Tipo de Usuário">
                                        <Select
                                            style={{
                                                width: '100%',
                                            }}
                                            options={[
                                                {
                                                    label: 'Comum',
                                                    value: 0
                                                },
                                                {
                                                    label: 'Admin',
                                                    value: 1
                                                }
                                            ]} 
                                            onChange={(e) => handleValue({ target: { value: e }}, 'role')}
                                            onInputKeyDown={(e) => e.preventDefault()}
                                            value={user?.role}
                                        />
                                    </Item>
                                </Col>
                            )}
                            
                        </Row>
                        {!user?.role && (
                            <Row 
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col className="gutter-row" span={6}>
                                    <Item label="Tipo de Usuário">
                                        <Select
                                            style={{
                                                width: '100%',
                                            }}
                                            options={[
                                                {
                                                    label: 'Comum',
                                                    value: 0
                                                },
                                                {
                                                    label: 'Admin',
                                                    value: 1
                                                }
                                            ]} 
                                            onChange={(e) => handleValue({ target: { value: e }}, 'role')}
                                            onInputKeyDown={(e) => e.preventDefault()}
                                            value={user?.role}
                                        />
                                    </Item>
                                </Col>
                            </Row>
                        )}
                        
                    </Form>
                </Col>
            </Row>
        </Modal>
    )
} 