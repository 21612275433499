import React, { useEffect, useState } from 'react'
import { 
    Typography, 
    Space, 
    Button,
    Row,
    Col,
    Select,
    Modal,
    Tag
} from 'antd'
import { useSWRConfig } from 'swr'
import { 
    Table,
    DestroyWorkerModal,
    WorkerModal,
} from '../../components'
import {
    PERMISSIONS
} from '../../environment'
import {
    PrincipalContainer
} from '../../containers'
import { Workers, PullRequest } from '../../modules'
import { 
    DeleteFilled,
    EditFilled
} from '@ant-design/icons'

const { Link, Text } = Typography

export default function WorkersPage ({ setCurrentPage, userPermissions }) {
    const { cache, mutate } = useSWRConfig()

    const { data: connectionsData } = cache.get('@connections') ?? { data: [] }
    const { data: workersData, isLoading: workersIsLoading } = cache.get('@workers') ?? { data: [] }
    const { data: groupsData, isLoading: groupsIsLoading } = cache.get('@groups') ?? { data: [] }

    const [isMobile, setIsMobile] = useState(false),
    [tableData, setTableData] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    [editData, setEditData] = useState({}),
    [createData, setCreateData] = useState({}),
    [destroyData, setDestroyData] = useState({}),
    [openEditModal, setOpenEditModal] = useState(false),
    [openCreateModal, setOpenCreateModal] = useState(false),
    [openDestroyModal, setOpenDestroyModal] = useState(false),
    [filterGroup, setFilterGroup] = useState(null),
    [groupsToFilter, setGroupsToFilter] = useState([]),
    [openPRAlertModal, setOpenPRAlertModal] = useState(false)

    const renderTagTable = (status) => {
        let tag = <></>

        switch (status) {
            case 0:
                tag = <Tag color="error">
                    {`offline`}
                </Tag>
                break
            case 1:
                tag = <Tag color="success">
                    {`online`}
                </Tag>
                break
            case 2:
                tag = <Tag color="default">
                    {`Desativada[offline]`}
                </Tag>
                break
        }

        return tag
    }

    const tableColumns = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                renderTagTable(row.status)
            )
        },
        {
            title: 'Conexão',
            dataIndex: 'connection_id',
            key: 'connection_id',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                <>
                    {connectionsData?.filter(conn => conn._id == row.connection_id)?.[0]?.name ?? '-'}
                </>
            ),
        },
        {
            title: 'Ações',
            dataIndex: 'actions',
            key: '_id',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) =>  {
                const worker = tableData.filter(worker => worker?._id === row?._id)?.[0] || {}

                return (
                    <Space size="middle">
                        {userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                            <Link onClick={() => handleEditModal(worker)} href='#'>
                                <EditFilled  style={{ fontSize: '16px' }} />
                            </Link>
                        )}
                        {userPermissions?.includes(PERMISSIONS.PERMISSION_DELETE) && (
                            <Link href='#' type='danger' onClick={() => handleDestroyModal(worker)}>
                                <DeleteFilled style={{ fontSize: '16px', color: '#d9534f' }} />
                            </Link>
                        )}
                    </Space>
                )
            },
        },
    ]
    
    const handleEditModal = (worker) => {
        if(worker?.has_open_pr) {
            setOpenPRAlertModal(true)
            return handleOpenPRAlertModal()
        }

        setEditData({ ...worker })
    }
    

    const handleDestroyModal = (worker) => {
        setDestroyData({ ...worker })
    }

    const handleOpenCreateModal = () => {
        setCreateData({ closer: 1 })
    }

    const mutating = async () => {
        await mutate('@workers', Workers.getClientWorkers({ group: filterGroup }))
        await mutate('@pull_requests', PullRequest.getPullRequests())

        const newCache = cache.get('@workers')?.data ?? []

        setTableData(newCache?.map((worker, i) => {
            return {
                key: String(i),
                ...worker
            }
        }) || [])

        setEditData({})
        setDestroyData({})
        setCreateData({})

        setIsLoading(false)
    }

    const handleOpenPRAlertModal = () => {
        return <Modal 
            title={'Pull Request aguardando ação'}
            centered
            open={openPRAlertModal}
            onCancel={() => setOpenPRAlertModal(false)}
            onOk={() => setOpenPRAlertModal(false)}
            okText="OK, irei verificar"
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <Text>{'Há Pull Requests aguardando merge ou desaprovação para esse Worker, verifique e tente novamente após não haver nenhum Pull Request pendente.'}</Text>
        </Modal>
    }

    const handleEditModalOpen = () => {
        return <WorkerModal 
            title={`Editando Worker: ${editData?.name}`} 
            open={openEditModal} 
            handleOpen={setOpenEditModal} 
            editWorker={editData} 
            mutating={mutating}
            isMobile={isMobile}
        />
    }

    const handleCreateModalOpen = () => {
        return <WorkerModal 
            title={`Criando novo Worker`} 
            open={openCreateModal} 
            handleOpen={setOpenCreateModal} 
            editWorker={createData} 
            mutating={mutating}
            isMobile={isMobile}
        />
    }

    const handleDestroyModalOpen = () => {
        return <DestroyWorkerModal 
            title={`Excluir Worker`} 
            open={openDestroyModal} 
            handleOpen={setOpenDestroyModal} 
            id={destroyData._id}
            name={destroyData.name} 
            mutating={mutating}
        />
    }

    const filterByGroup = (groupId) => {
        setFilterGroup(groupId)
        setIsLoading(true)
    }

    useEffect(() => {
        if(Object.keys(createData).length) setOpenCreateModal(true)
        else setOpenCreateModal(false)
    }, [createData])

    useEffect(() => {
        if(Object.keys(editData).length) {
            setCreateData({})
            setOpenEditModal(true)
        }
        else setOpenEditModal(false)
    }, [editData])

    useEffect(() => {
        if(Object.keys(destroyData).length) setOpenDestroyModal(true)
        else setOpenDestroyModal(false)
    }, [destroyData])

    useEffect(() => {
        setTableData(workersData?.map((worker, i) => {
            return {
                key: String(i),
                ...worker
            }
        }) || [])
    }, [isLoading, workersIsLoading])
    
    useEffect(() => {
        setGroupsToFilter(groupsData?.map((group, i) => {
            return {
                label: group.name,
                value: group._id
            }
        }) || [])

        setIsLoading(false)
    }, [groupsIsLoading])

    useEffect(() => {
        mutating()
    }, [filterGroup])

    window.addEventListener('mobileResize', () => {
        setIsMobile(true)
    })

    window.addEventListener('desktopResize', () => {
        setIsMobile(false)
    })

    return (
        <>
            {
                handleOpenPRAlertModal()        
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                    handleEditModalOpen()
                )
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                    handleCreateModalOpen()
                )
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_DELETE) && (
                    handleDestroyModalOpen()
                )
            }
            <PrincipalContainer menuItemSelected='15' setCurrentPage={setCurrentPage} content={
                <>
                    <Typography.Title level={2} style={{ textAlign: 'left' }}>
                        Workers
                        <Select
                            defaultValue={''}
                            style={{
                                width: 150,
                                marginLeft: 15
                            }}
                            onSelect={(e) => filterByGroup(e)}
                            options={[
                                {
                                    label: 'Filtrar por grupo',
                                    value: ''
                                },
                                ...groupsToFilter
                            ]}
                        />
                        {userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                            <Button style={{ float: 'right', top: 5 }} type="primary" shape="round" onClick={handleOpenCreateModal}>+</Button>
                        )}
                    </Typography.Title>
                    <Row>
                        <Col style={{ overflow: 'scroll' }} span={24}>
                            <Table 
                                columns={tableColumns}
                                isLoading={isLoading}
                                tableData={tableData}
                            />
                        </Col>
                    </Row>
                </>
            } />
        </>
    )
}