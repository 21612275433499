import React, { useState, useEffect } from 'react'
import { 
    Col,
    Row,
    Steps,
    Typography
} from 'antd'
import {
    PrincipalContainer
} from '../../containers'
import { useSWRConfig } from 'swr'
import { 
    Apps, 
    Groups, 
    ACL, 
    Connections, 
    Server,
    User,
    UserPolicies,
    PullRequest,
    Migration,
    Libs,
    Keys,
    Workers,
    AuthApp
} from '../../modules'

const { Title } = Typography

export default function GetStartedPage ({ setCurrentPage }) {
    const { cache, mutate } = useSWRConfig()

    const [isMobile, setIsMobile] = useState(false),
        [isLoading, setIsLoading] = useState(true)

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        }
    }

    const steps = [
        {
            title: 'Crie um grupo',
            description: 'Crie um grupo para organizar suas APIs e Workers (opcional)',
            status: 'process'
        },
        {
            title: 'Crie um ACL',
            description: 'Crie um ACL para hierarquizar suas APIs (opcional)',
            status: 'process'
        },
        {
            title: 'Crie uma conexão com o BD',
            description: 'Crie uma conexão para usar em suas APIs e Workers (obrigatório)',
            status: 'process'
        },
        {
            title: 'Crie uma api',
            description: 'Crie uma API para integrar com seus sistemas',
            status: 'process'
        },
        {
            title: 'Explore todas as ferramentas do SimpleTOOL',
            description: '',
            status: 'process'
        }
    ]

    const startCache = async () => {
        try {
            await mutate('@apps', Apps.getClientApps({}))
            await mutate('@connections', Connections.getClientConnections({}))
            await mutate('@groups', Groups.getClientGroups({}))
            await mutate('@acls', ACL.getClientACLs({}))
            await mutate('@users', User.getUsers({}))
            await mutate('@usersPolicies', UserPolicies.getUsersPolicies({}))
            await mutate('@server', Server.getServerIP({}))
            await mutate('@pull_requests', PullRequest.getPullRequests({}))
            await mutate('@migrations', Migration.getClientMigrations({}))
            await mutate('@libs', Libs.getClientLibs({}))
            await mutate('@keys', Keys.getClientKeys({}))
            await mutate('@workers', Workers.getClientWorkers({}))
            await mutate('@authApp', AuthApp.getClientAuthApp({}))
        } catch (error) {
            console.log(error)            
        }
        
        setIsLoading(false)
    }

    useEffect(() => {
        if(!cache.get('@apps')?.length) startCache()
    }, [])

    useEffect(() => {
        const handleResize = () => {
            const {
                width
            } = getWindowDimensions()

            if(width <= 768) setIsMobile(true)
            else setIsMobile(false)
        } 

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [localStorage.getItem('@user')])

    return (
        <PrincipalContainer 
            menuItemSelected='6' 
            setCurrentPage={setCurrentPage} content={
                <>
                    <Title style={{ marginBottom: 100 }}>
                        Siga os passos abaixo para começar com o SimpleTOOL
                    </Title>
                    <Row>
                        <Col style={{ overflow: 'scroll' }} span={24}>
                            <Steps
                                style={{ display: 'block', margin: '0 auto', width: isMobile ? '100%' : '30%' }}
                                current={5}
                                direction="vertical"
                                items={steps}
                            />
                        </Col>
                    </Row>
                </>
            } 
            isLoading={isLoading}
        />
    )
    
}