import React, { useEffect, useState } from "react"
import { 
    Modal,
    Form,
    Col,
    Row,
    Collapse,
    Typography
} from 'antd'
import { 
    Logs
} from "../../../modules"
import DateFormat from '../../../helpers/date-format' 
import { useSWRConfig } from "swr"
import Editor from '@monaco-editor/react'

const { Panel } = Collapse

export default function LogsModal ({ title = '', open = false, handleOpen = () => {}, logsData = {} }) {
    const { mutate } = useSWRConfig()

    const [logs, setLogs] = useState(null),
        [loading, setLoading] = useState(true)

    const startLogs = async () => {
        try {
            const { data } = await mutate('@logs', Logs.getClientAppLogs({ appId: logsData._id }))

            setLogs({ ...data })
        } catch (error) {
            console.log(error)            
        }
        
        setLoading(false)
    }

    useEffect(() => {
        if(Object.keys(logsData)?.length) {
            startLogs()
        }
    }, [logsData])

    return (
        <Modal
            title={title}
            centered
            open={open}
            onCancel={async () => { 
                handleOpen(false) 
            }}
            width={1000}
            onOk={async () => handleOpen(false)}
            cancelText="Cancelar"
            cancelButtonProps={{ type: 'text' }}
            okButtonProps={{ 
                style: {
                    display: "none"
                }
            }}
            // okButtonProps={{ loading: loading }}
        >
            {!loading && (
                <Row 
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col span={24}>

                        {
                            Object.keys(logs ?? {})?.length && (
                                <Collapse accordion bordered={false}>
                                    {
                                        Object.keys(logs ?? {}).sort((a,b) => new Date(logs[a].date) <= new Date(logs[b].date)).map(key => {
                                            const log = logs[key]

                                            return (
                                                <Panel header={`Log - ${DateFormat(log.date)}`} key={key}>
                                                    <Editor 
                                                        language='json'
                                                        value={log && JSON.stringify(log, null, 4) || JSON.stringify({ msg: 'Nenhum log à demonstrar' }, null, 4)}
                                                        theme='vs-dark'
                                                        height="75vh" 
                                                        options={{
                                                            autoIndent: "full",
                                                            readOnly: true
                                                        }}
                                                    />
                                                </Panel>
                                            )
                                    })
                                } 
                            </Collapse>) || <Typography.Title level={5} style={{ textAlign: 'center' }}>Nenhum log encontrado</Typography.Title>
                        }

                    </Col>
                </Row>
            )}
        </Modal>
    )
} 