import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
    verifyPermission, 
    permissionsStatus 
} from '../../helpers/permissions'

const getClientLibs = async ({ limit = 500, offset = 0 }) => {
    if(!verifyPermission('libs', permissionsStatus.list)) return []

    const { data } = await API.get(URLS.libs.list(limit, offset))

    return data
}

const createClientLibs = async ({ connection }) => {
    if(!verifyPermission('libs', permissionsStatus.create)) return {}

    const { data: response, isError } = await API.post(URLS.libs.create, connection)

    return response?.data || isError
}

const updateClientLibs = async ({ connection, id }) => {
    if(!verifyPermission('libs', permissionsStatus.update)) return {}

    const { data: response, isError } = await API.put(URLS.libs.update(id), connection)

    return response?.data || isError
}

const deleteClientLibs = async ({ id }) => {
    if(!verifyPermission('libs', permissionsStatus.delete)) return {}

    const { data: response, isError } = await API.delete(URLS.libs.delete(id))

    return response?.data || isError
}

const getLibsLogs = async ({ connectionId, limit = 500, offset = 0 }) => {
    if(!verifyPermission('libs', permissionsStatus.list)) return []

    if(connectionId == null) return []

    const { data: response } = await API.get(URLS.libs.logs(connectionId, limit, offset))

    return response?.data
}

const Libs = {
    getClientLibs,
    createClientLibs,
    updateClientLibs,
    deleteClientLibs,
    getLibsLogs
}

export default Libs