import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
    verifyPermission, 
    permissionsStatus 
} from '../../helpers/permissions'

const getClientACLs = async ({ limit = 500, offset = 0 }) => {
    if(!verifyPermission('acl', permissionsStatus.list)) return []
    
    const { data } = await API.get(URLS.acl.list(limit, offset))

    return data
}

const createClientACL = async ({ acl }) => {
    if(!verifyPermission('acl', permissionsStatus.create)) return {}


    const { data: response } = await API.post(URLS.acl.create, acl)

    return response?.data
}

const updateClientACL = async ({ acl, id }) => {
    if(!verifyPermission('acl', permissionsStatus.update)) return {}

    const { data: response } = await API.put(URLS.acl.update(id), acl)

    return response?.data
}

const deleteClientACL = async ({ id }) => {
    if(!verifyPermission('acl', permissionsStatus.delete)) return []

    const { data: response } = await API.delete(URLS.acl.delete(id))

    return response?.data
}

const ACL = {
    getClientACLs,
    createClientACL,
    updateClientACL,
    deleteClientACL
}

export default ACL