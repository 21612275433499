import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
    verifyPermission, 
    permissionsStatus 
} from '../../helpers/permissions'

const getClientBillings = async ({ year, limit = 12, offset = 0 }) => {
    if(!verifyPermission('billing', permissionsStatus.list)) return []

    const { data } = await API.get(URLS.billing.list(limit, offset, year))

    return data
}

const Billing = {
    getClientBillings
}

export default Billing