import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
    verifyPermission, 
    permissionsStatus 
} from '../../helpers/permissions'

const getClientGroups = async ({ limit = 500, offset = 0 }) => {
    if(!verifyPermission('group', permissionsStatus.list)) return []

    const { data } = await API.get(URLS.groups.list(limit, offset))

    return data
}

const createClientGroup = async ({ group }) => {
    if(!verifyPermission('group', permissionsStatus.create)) return {}

    const { data: response } = await API.post(URLS.groups.create, group)

    return response?.data
}

const updateClientGroup = async ({ group, id }) => {
    if(!verifyPermission('group', permissionsStatus.update)) return {}

    const { data: response } = await API.put(URLS.groups.update(id), group)

    return response?.data
}

const deleteClientGroup = async ({ id }) => {
    if(!verifyPermission('group', permissionsStatus.delete)) return {}

    const { data: response } = await API.delete(URLS.groups.delete(id))

    return response?.data
}

const Group = {
    getClientGroups,
    createClientGroup,
    updateClientGroup,
    deleteClientGroup
}

export default Group